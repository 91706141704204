import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'


import { authHooks, authContextHelper } from './../../utilities'
import { Username } from '../../components/auth'



const RequestCode: React.FunctionComponent = () => {

  const { username, setUsername, usernameIsValid } = authHooks.useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !usernameIsValid || username.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
    } catch (err) {
      setError('Unknown user')
    }
  }

  const emailSent = (
    <>
      <div>
        <p>{`Reset Code Sent to ${username}`}</p>
      </div>
      <div>
        <button onClick={() => history.push('/forgotpassword')}>
          Reset Password
        </button>
      </div>
    </>
  )

  const sendCode = (
    <>
      <div>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </div>
      <div>
        <p>
          {error}
        </p>
      </div>

      <div>
        
        <button onClick={() => history.goBack()}>
          Cancel
        </button>
        <hr/>

        <button disabled={isValid} onClick={sendCodeClicked}>
          Send Code
        </button>
  
      </div>
    </>
  )

  return (
    <>
      <div>
        <h3>Send Reset Code</h3>
      </div>
      <hr/>
      <div>
        {resetSent ? emailSent : sendCode}
      </div>
    </>
  )
}


export default RequestCode;