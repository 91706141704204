import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { helpers } from './..';

import { amplifyCognito, bsgCrypto } from '../services';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string },
  userProfile?: any
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  refreshSessionInfo?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  getApiSessionTokens?: any
  getAmplifyCurrentAthenticatedUser?: any
  userKeys?: bsgCrypto.CryptoData
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

export const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [userProfile, setUserProfile] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [userKeys, setUserKeys] = useState<bsgCrypto.CryptoData|undefined>(undefined)

  const history = useHistory()


  helpers.useInterval(() => {
    refreshSessionInfo();

  }, 1000 * 60 * 4);
  // Refresh Tokens every 4 min interval

  helpers.useInterval(async () => {
    try {
      const user = await getAmplifyCurrentAthenticatedUser();
    } catch (error) {
      history.push("/signin")
    }

  }, 1000 * 10);

  const getUserKeys = async () => {
    const session: any = await getSession();
    const userKeys = await amplifyCognito.getDeserialisedUserKeys({username: session.idToken.payload["cognito:username"]});
    setUserKeys(userKeys);
  }

  const getSessionInfo = async () => {
    try {
      const session: any = await getSession();

      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
      })
      
      setUserProfile({
        authToken: session.idToken.jwtToken,
        username: session.idToken.payload["cognito:username"] 
      })
      
      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
      // await setAttribute({ Name: 'website', Value: 'https://kuroweb.bsg-dev.com' })
      const attr: any = await getAttributes()
      setAttrInfo(attr)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)

      history.push("/")
    }
  };

  useEffect(() => {

    getSessionInfo();
    getUserKeys();
    
  }, [setAuthStatus, authStatus, history])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithEmail(username: string, password: string, newPassword?: string, userMfaCode?: string, setMfaSetupCode?: string) {
    try {
      await amplifyCognito.signInWithEmail(username, password, newPassword, userMfaCode, setMfaSetupCode);
      // console.log("Sign in Response: Auth Provider: ", signInResponse);

      const userKeys = await amplifyCognito.verifyUserKeys(username, newPassword || password);
      setUserKeys(userKeys);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      //console.log("Error during signin or key gen", err)
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signUpWithEmail(username: string, email: string, password: string) {
    try {
      await amplifyCognito.signUpUserWithEmail(username, email, password)
    } catch (err) {
      throw err
    }
  }

  async function signOut() {
    await amplifyCognito.signOut();

    setAuthStatus(AuthStatus.SignedOut);
    setSessionInfo({})
    setUserProfile({})
    setAttrInfo([])

    window.localStorage.setItem("accessToken", "");
    window.localStorage.setItem("refreshToken", "");

    history.push("/")
  }

  async function verifyCode(username: string, code: string) {
    try {
      await amplifyCognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await amplifyCognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getApiSessionTokens() {
    try {
      const session: any = await amplifyCognito.getSession()
      return {
        authToken: session.idToken.jwtToken,
        username: session.idToken.payload["cognito:username"]
      }
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await amplifyCognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await amplifyCognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await amplifyCognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, newPassword?: string) {
    try {
      await amplifyCognito.forgotPassword(username, code, newPassword)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await amplifyCognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  async function refreshSessionInfo() {
    try {
      const session: any = await getSession()

      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
      })
      
      setUserProfile({
        authToken: session.idToken.jwtToken,
        username: session.idToken.payload["cognito:username"] 
      })
      
      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
      // await setAttribute({ Name: 'website', Value: 'https://kuroweb.bsg-dev.com' })
      const attr: any = await getAttributes()
      setAttrInfo(attr)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)

      history.push("/")
    }
  }

  async function getAmplifyCurrentAthenticatedUser() {
    try {
      return await amplifyCognito.getAmplifyCurrentAthenticatedUser()
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    userProfile,
    userKeys,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    refreshSessionInfo,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    getApiSessionTokens,
    getAmplifyCurrentAthenticatedUser,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

