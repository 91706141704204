import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { authHooks, authContextHelper } from './../../utilities'
import { Code, Username } from '../../components/auth'


const VerifyCode: React.FunctionComponent<{}> = () => {

  const { username, setUsername, usernameIsValid } = authHooks.useValidUsername('')
  const { code, setCode, codeIsValid } = authHooks.useValidCode('')
  const [error, setError] = useState('')

  const isValid = !usernameIsValid || username.length === 0 || !codeIsValid || code.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  const sendClicked = async () => {
    try {
      await authContext.verifyCode(username, code)
      history.push('/signin')
    } catch (err) {
      setError('Invalid Code')
    }
  }

  const passwordResetClicked = async () => {
    history.push('/resetpassword')
  }

  return (
    <>
      <div>
        <div>
          <h2>Send Code</h2>
        </div>
        <hr/>
        <div>
          <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
        </div>
        <br/>
        
        <div>
          <Code codeIsValid={codeIsValid} setCode={setCode} />
        </div>

        <hr/>

        <button onClick={passwordResetClicked} >
          <p>
            Resend Code
          </p>
        </button>
        <br/>
        <div>
          <p>
            {error}
          </p>
        </div>
        <hr/>
        <button onClick={() => history.goBack()}>
          <p>
            Cancel
          </p>
        </button>
        <button disabled={isValid} onClick={sendClicked}>
          <p>
            Send
          </p>
        </button>

      </div>
    </>
  )
}

export default VerifyCode
