
import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Card, Image} from 'react-bootstrap';

import {
  windowsRunningIcon,
  windowsStoppedIcon,
  windowsHibernatedIcon,
  windowsBuildingIcon,
} from "./../../assets/images";

import { HighlightedProductImage } from "./../";


const GenericStatisticWidget: React.FunctionComponent<any> = (props:any) => {
  const { header, description, numberCount } = props;

  return (
    <>
      {/* <Card border="light" className="shadow" style={{ height: "80px"}}>

        <Container style={{ position: "absolute"}}>
          <Row>
            <Col md={9}>
              <div style={{marginTop: "3%", }}>
              <h6 style={{ color: "grey", marginBottom: "2%"}}>
                {header}
              </h6>
              <small style={{ color: "grey", fontSize: "0.7rem"}}>
                {description}
              </small>
              </div>
            </Col>
            <Col md={3}>
              <h6 style={{ color: colour, marginBottom: "2%", fontSize: "3rem"}}>
                {numberCount}
              </h6>
            </Col>
          </Row>
        </Container>
      </Card> */}
      <Card className="card mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "200px", maxWidth: "200px"}}>
        <Row className="row">
          <Col className="col-9  pe-0">
            <Card.Body className="card-body ps-2 pe-1 py-2" >
              <div className="card-header-0">{header}<br/></div>
              <div className="card-subheader-0"> {description}</div>
            </Card.Body>
          </Col>
          <Col className="col-3 px-1" >
            <Card.Body className="card-body px-1 pt-2 pb-0">
              <div className="card-value-0 pr-3">{numberCount}</div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const HighlightedResource: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status } = props;
 
  return (
    <>
      {/* <Card border="light" className="shadow" style={{ height: "80px"}}>
      
        <div style={{width: "3%", backgroundColor: colour, height: "inherit",}}>
        </div>

        <Container style={{ width: "97%", position: "absolute"}}>
          <Row>
            <Col md={3}>
              <img src={image} style={{ margin: "15%"}} alt={`highlighted-${header}-img`}/>
            </Col>

            <Col md={9}>
              <div style={{marginTop: "3%", }}>
              <h6 style={{ color: "grey", marginBottom: "2%"}}>
                {header}
              </h6>
              <small style={{ color: "grey", fontSize: "0.7rem"}}>
                {description}
              </small>
              </div>
            </Col>
          </Row>
        </Container>
      </Card> */}

      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    {(status === "hibernation") || (status === "hibernating") ? 
                      <Image src={windowsHibernatedIcon}></Image>
                      :
                      null
                    }
                    {(status === "running") || (status === "starting") ? 
                      <Image src={windowsRunningIcon}></Image>
                      :
                      null
                    }
                    {(status === "stopped") || (status === "stopping") ? 
                      <Image src={windowsStoppedIcon}></Image>
                      :
                      null
                    }
                    {(status === "building")  ? 
                      <Image src={windowsBuildingIcon}></Image>
                      :
                      null
                    }
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProduct: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status, product, system } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <HighlightedProductImage
                      product={product}
                      status={status}
                      system={system}
                    />
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProductDetail: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status, product, system } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <HighlightedProductImage
                      product={product}
                      status={status}
                      system={system}
                    />
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProfile: React.FunctionComponent<any> = (props:any) => {
  const { header, description, image, linkPath } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-profile" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <Image src={image} className="k-mh-40"></Image>
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProject: React.FunctionComponent<any> = (props:any) => {
  const { header, description, image, linkPath } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-project" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <Image src={image} className="k-mh-40"></Image>
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};


// const GenericStatisticWidgetOld: React.FunctionComponent<any> = (props:any) => {
//   const { icon, iconColor, category, title, period, percentage } = props;
//   const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
//   const percentageColor = percentage < 0 ? "text-danger" : "text-success";

//   return (
//     <Card border="light" className="shadow">
//       <div style={{width: "20px", backgroundColor: "red", height: "100%"}}>

//       </div>
//       <div style={{width: "2%", backgroundColor: "red"}}>

//       </div>
//       <Card.Body>
//         <Container>

//           <Row className="d-block d-xl-flex align-items-center" style={{ left: "10%"}}>
//             <Col xl={3} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
//               <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
//                 <FontAwesomeIcon icon={icon} />
//               </div>
//               <div className="d-sm-none">
//                 <h5>{category}</h5>
//                 <h4 className="mb-1">{title}</h4>
//               </div>
//             </Col>
//             <Col xs={12} xl={7} className="px-xl-0">
//               <div className="d-none d-sm-block">
//                 <h5>{category}</h5>
                
//               </div>
//               <small>{period}, <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> WorldWide</small>
              
//             </Col>
//             <Col xs={12} xl={2}>
//               <h5>{percentage}</h5>
//             </Col>
//           </Row>

//         </Container>

        
//       </Card.Body>
//     </Card>
//   );
// };

// const CircleChartWidget: React.FunctionComponent = (props:any) => {
//   const { title, data = [] } = props;
//   const series = data.map((d: any)=> d.value);

//   return (
//     <Card border="light" className="shadow-sm">
//       <Card.Body>
//         <Row className="d-block d-xl-flex align-items-center">
//           <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
//             <CircleChart series={series} />
//           </Col>
//           <Col xs={12} xl={7} className="px-xl-0">
//             <h5 className="mb-3">{title}</h5>

//             {data.map((d: any) => (
//               <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
//                 <FontAwesomeIcon icon={d.icon} className={`icon icon-xs text-${d.color} w-20 me-1`} />
//                 {` ${d.label} `}{`${d.value}%`}
//               </h6>
//             ))}
//           </Col>
//         </Row>
//       </Card.Body>
//     </Card>
//   );
// };



export {
  GenericStatisticWidget, 
  HighlightedResource,
  HighlightedProfile,
  HighlightedProject,
  HighlightedProduct,
  HighlightedProductDetail
};

