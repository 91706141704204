import React from 'react';

import {
  AuthenticatedRoutes,
  OpenRoutes, 
} from './../routes';


const AuthenticatedLayout: React.FunctionComponent = () => {
  return (
  <>
    <AuthenticatedRoutes />
  </>
  );
};



const OpenLayout: React.FunctionComponent = () => {
  return (<>
    <OpenRoutes />
  </>
  );
};



export {
  AuthenticatedLayout,
  OpenLayout
}