import React, { useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container } from 'react-bootstrap';

import { authHooks, authContextHelper } from './../../utilities'
import { Email, Password, Username } from '../../components/auth'

import {backgroundSignin} from "./../../assets/images";

const SignUp: React.FunctionComponent<{}> = () => {

  const { email, setEmail, emailIsValid } = authHooks.useValidEmail('')
  const { password, setPassword, passwordIsValid } = authHooks.useValidPassword('')
  const { username, setUsername, usernameIsValid } = authHooks.useValidUsername('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = authHooks.useValidPassword('')

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  const signInClicked = async () => {
    try {
      await authContext.signUpWithEmail(username, email, password)
      setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const signUp = (
    <>
      <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <div className="text-center text-md-center mb-4 mt-md-0">
          <h3 className="mb-0">Create an Account</h3>
        </div>
        <Form className="mt-4">
          <Form.Group id="email" className="mb-4">
            <Form.Label>Your Email</Form.Label>
            <Email emailIsValid={emailIsValid} setEmail={setEmail} />
          </Form.Group>
          <Form.Group id="email" className="mb-4">
            <Form.Label>Your Email</Form.Label>
            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
          </Form.Group>
          <Form.Group id="password" className="mb-4">
            <Form.Label>Your Password</Form.Label>
            <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>Confirm Password</Form.Label>
            <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
          </Form.Group>
          <FormCheck type="checkbox" className="d-flex mb-4">
            <FormCheck.Input required id="terms" className="me-2" />
            <FormCheck.Label htmlFor="terms">
              I agree to the <Card.Link>terms and conditions</Card.Link>
            </FormCheck.Label>
          </FormCheck>

          <Button variant="primary" type="button" className="w-100" disabled={isValid}  onClick={signInClicked}>
            Sign up
          </Button>
        </Form>

        <div className="mt-3 mb-4 text-center">
          <span className="fw-normal">or</span>
        </div>
        <div className="d-flex justify-content-center my-4">
          <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
            <FontAwesomeIcon icon={faTwitter} />
          </Button>
          <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
            <FontAwesomeIcon icon={faGithub} />
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <span className="fw-normal">
            Already have an account?
            <Card.Link as={Link} to="/signin" className="fw-bold">
              {` Login here `}
            </Card.Link>
          </span>
        </div>
      </div>
    </>
  )

  const accountCreated = (
    <>
      <p>{`Created ${username} account`}</p>
      <p>{`Verfiy Code sent to ${email}`}</p>

      <div>
        <button onClick={() => history.push('/verify')} >
          Send Code
        </button>
      </div>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <h3 className="mb-4 text-center">Password Changed</h3>
        <p>{`Created ${username} account`}</p>
        <p>{`Verfiy Code sent to ${email}`}</p>
        <hr/>
        <p className="text-center">
          <Card.Link onClick={() => history.push('/verify')} className="text-gray-700">
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Send Code
          </Card.Link>
          {}
        </p>
      </div>
    </>
  )

  return (
    <>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to="/" className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to Homepage
              </Card.Link>
            </p>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${backgroundSignin})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                {!created ? signUp : accountCreated}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default SignUp
