import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Container} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { 
  HighlightedResourceAddButton, 
  HighlightedProduct,
  SelectedDesktop, 
  ProductDeleteModal,
  DesktopAddModal,
  DesktopsTable
} from "./../../components";


import { 
  authContextHelper, 
  api, 
  helpers,
  bsgCrypto,
  variables
} from './../../utilities';

import {
  resourceAddIcon
} from "./../../assets/images";


const Desktops: React.FunctionComponent = (props: any) => {
  const DEFAULT_PRODUCT_TYPE = "desktop";
  //console.log("ALL PROPS: ", props)
  const { setLoaded, urlParameters, currentHistory } = props;
  const id = urlParameters.get("id");

  const auth = useContext(authContextHelper.AuthContext);
  const [desktops, setDesktops] = useState<any>([]);
  const [selectedDesktop, setSelectedDesktop] = useState<any>(null);
  const [selectedDesktopNotes, setSelectedDesktopNotes] = useState<any>([]);
  const [isShowDeleteDesktopModal, setDeleteDesktopModalShow] = useState(false);
  const [selectedDesktopStatus, setSelectedDesktopStatus] = useState("PENDING");
  const [desktopsListDeleteObject, setDesktopsListDeleteObject] = useState<any>(null);
  const [isShowAddDesktopModal, setAddDesktopModalShow] = useState(false);
  const [addDesktopModalSubmitError, setAddDesktopModalSubmitError] = useState<any>(null);

  const [desktopVaultPasswordSubmitError, setDesktopVaultPasswordSubmitError] = useState<any>(null);
  const [desktopVaultSecretEditSubmitError, setDesktopVaultSecretEditSubmitError] = useState<any>(null);

  const [selectedDesktopVault, setSelectedDesktopVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("")

  
  const [groups, setGroups] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  

  // const [userGroupIds, setGroupsIds] = useState<any>([]);

  const handleDesktopStart = async(event: any) => {
    event.preventDefault();

    await api.startProduct({
      body: {
        id: id
      }
    });

    getDesktops();
  }

  const handleIframeMouseOver = async(event: any) => {
    event.preventDefault();
    const iframeDocument = document.getElementById(event.target.id);
    iframeDocument?.focus();
  }

  const handleDesktopStop = async (event: any) => {
    event.preventDefault();

    await api.stopProduct({
      body: {
        id: id
      }
    });

    getDesktops();
  }

  const handleDesktopFullScreen = (event: any) => {
    event.preventDefault();
  }

  const handleDesktopDelete = (event: any) => {
    event.preventDefault();

    setDeleteDesktopModalShow(true)
  }

  const handleDesktopsListDelete = (event: any, desktopsListDeleteObjectItem: any) => {
    event.preventDefault();

    setDeleteDesktopModalShow(true)
    setDesktopsListDeleteObject(desktopsListDeleteObjectItem)
  }

  const handleDesktopHibernate = async(event: any) => {
    event.preventDefault();

    await api.hibernateProduct({
      body: {
        id: id
      }
    });

    getDesktops();
  }

  const handleCloseDeleteDesktopModal = (event: any) => {
    // event.preventDefault();
    setDeleteDesktopModalShow(false)
  };

  const handleDesktopDeleteSubmit = async() => {
    // setLoaded(false);
    const { result } =  await api.deleteProduct({
      body: {
        id: id
      }
    });

    if (result === "Success"){
      handleCloseDeleteDesktopModal(true);
      currentHistory.push("/");
    }
    // setLoaded(true);
  }

  const handleDesktopsListDeleteSubmit = async() => {
    // setLoaded(false);
    const { result } =  await api.deleteProduct({
      body: {
        id: desktopsListDeleteObject && desktopsListDeleteObject.id
      }
    });

    if (result === "Success"){
      handleCloseDeleteDesktopModal(true);
      // currentHistory.push("/desktops");
      window.location.reload();
    }
    // setLoaded(true);
  }

  const handleShowAddDesktopModal = (event: any) => {
    // event.preventDefault();
    setAddDesktopModalShow(true)
  };

  const handleCloseAddDesktopModal = (event: any) => {
    // event.preventDefault();
    setAddDesktopModalShow(false)
  };

  const handleDesktopAddSubmit = async(query: any) => {
    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);
          
    const submitResponse =  await api.postProduct({
      body: query.body, 
      productType: variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      setAddDesktopModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseAddDesktopModal(null);
      currentHistory.push(`/desktops?id=${submitResponse.id}`);

    } else {
      //console.log("ERROR RESPONSE: PRODUCT PUT: ", submitResponse);

    }
  }

  const getDesktops = async() => {
    setLoaded(false);
    
    if(id){
      const desktopData = await api.getProduct({
        body: {
          id: id
        }
      });

      setSelectedDesktop(desktopData);
      setSelectedDesktopStatus(desktopData.state.toUpperCase());
      
      getSelectedDesktopNotes();
    }

    const desktopsData = await api.getProducts({
      productTypes: [variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY]
    });

    setDesktops(desktopsData);
    setLoaded(true);
  }

  const getDesktopsStatuses = async () => {
    if(id){
      const desktopData = await api.getProduct({
        body: {
          id: id
        }
      });

      setSelectedDesktop(desktopData);
      setSelectedDesktopStatus(desktopData.state.toUpperCase());
    }
  }

  const getGroups = async() => {
    setLoaded(false);

    const groupsData = await api.getSecurityGroups({});

    setGroups(groupsData);
    setLoaded(true);
  }

  const getProfiles = async() => {
    setLoaded(false);
    const profilesData = await api.getProfiles({});

    setProfiles(profilesData);
    setLoaded(true);  
  }

  const getProjects = async() => {
    setLoaded(false);
    const projectsData = await api.getProjects({});

    setProjects(projectsData);
    setLoaded(true);  
  }

  const getRegions = async() => {
    setLoaded(false);
    const regionsData = await api.getRegions({});
    setRegions(regionsData);
    setLoaded(true);
  }

  const getSelectedDesktopNotes = async() => {
    if(id){
      const desktopNotes = await api.getResourceNotes({resourceId: id, resourceType: "product"});
      setSelectedDesktopNotes(desktopNotes && desktopNotes.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
    }
  }

  const getSelectedDesktopVault = async() => {
    //console.log("Resource Vault: Refresh: 1: ID:, ", id);
    //console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(id && decryptedVaultKey){
      const desktopVaultData = await api.getResourceVault({
        resourceId: id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
      });

      //console.log("Resource Vault: Refresh: 2: Response: ", desktopVaultData);
      //console.log("Resource Vault: Refresh: 2: Data: ", desktopVaultData.data);

      const desktopVaultRefreshed = new bsgCrypto.Vault(desktopVaultData.data, decryptedVaultKey);
      //console.log("Resource Vault: Refresh: 3: Data Updated: ", desktopVaultRefreshed);
      
      if(selectedDesktop.security && selectedDesktop.security !== "NULL" ) {
        await helpers.checkAndAddMissingUserVaultKeys(id, variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, desktopVaultRefreshed);
      }

      setSelectedDesktopVault(desktopVaultRefreshed);
    }
  }

  const handlePutDesktopSecurityGroupSubmit = async(event: any, query: any) => {
    event.preventDefault();
    const submitResponse =  await api.putResourceSecurityGroup({
      resourceId: selectedDesktop.id, 
      securityGroupID: query.id,
      resourceType: "product" 
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      // const newName = query.name;
      // setGroupName(newName);
      toast.success('Security Group has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutDesktopProfileSubmit = async(event: any, query: any) => {
    event.preventDefault();

    const submitResponse =  await api.putProductProfile({
      id: selectedDesktop.id, 
      profileID: query.id 
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      toast.success('Profile has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutDesktopProjectSubmit = async(event: any, query: any) => {
    event.preventDefault();

    //console.log("Attempting to Updated Desktop's project: TBD...");

    toast.success('Project has been changed successfully', {
      position: "top-right",
      autoClose: 5000,
      theme: "light",
    });
  }

  const handleGetDesktopNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({resourceId: id, noteId: event.noteID, resourceType: "product"});
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutDesktopNote = async(event: any) => {
    //console.log("LOG NOTE ERROR Event: ", event);
    const noteBody = event.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    //console.log("LOG NOTE ERROR Body: ", noteBody);
    //console.log("LOG NOTE ERROR noteTmp: ", noteTmp);
    //console.log("LOG NOTE ERROR firstLine: ", firstLine);
    //console.log("LOG NOTE ERROR secondLine: ", secondLine);

    if (noteTmp.split('\n').length > 1) {
      //const secondLine = noteTmp.split('\n')[1].substring(0, 35);

      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        headline: secondLine, 
        title: firstLine
      });
    } else {
      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        title: firstLine
      });
    }
    getSelectedDesktopNotes();
  }

  const handlePostDesktopNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getSelectedDesktopNotes();
  }

  const handleDeleteDesktopNote = async(noteID: any) => {

    //console.log("LOG NOTE handleDeleteProductNote desktop : ", noteID)

    document.getElementById(`notecard-${noteID}`)?.classList.add('flash');
    await api.deleteResourceNote({
      resourceId: id, 
      noteId: noteID,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
    });

    await getSelectedDesktopNotes();
    document.getElementById(`notecard-${noteID}`)?.classList.remove('flash');
  }
  
  const handleDycryptVaultSubmit = async(query: any) => {
    const desktopVaultData = await api.getResourceVault({
      resourceId: id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    //console.log("Resource Vault: 1: ", desktopVaultData.data);
    const base64EncodedVaultKey = desktopVaultData.data.key[auth.userProfile.username]!;
    //console.log("Resource Vault: 2: ", desktopVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    //console.log("Resource Vault: 3: ", desktopVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    //console.log("Resource Vault: 4: ", desktopVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const desktopVaultRefreshed = new bsgCrypto.Vault(desktopVaultData.data, unwrappedVaultKey);
      //console.log("Resource Vault: 5: Data Updated: ", desktopVaultRefreshed);

      if(selectedDesktop.security && selectedDesktop.security !== "NULL" ) {
        await helpers.checkAndAddMissingUserVaultKeys(id, variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, desktopVaultRefreshed);
      }        
      setSelectedDesktopVault(desktopVaultRefreshed);
    } else {
      setDesktopVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostDesktopVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    //console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await selectedDesktopVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      //console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      //console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getSelectedDesktopVault();
  }


  const handleDeleteDesktopVaultPassword = async(query: any) => {
    //console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await selectedDesktopVault!.removeItemFromVault(query.body.secretId);

      //console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      //console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
          secretId: query.body.secretId
        });

        //console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getSelectedDesktopVault();
  }


  const handleEditDesktopVaultPassword = async(query: any) => {
    //console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      //console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await selectedDesktopVault!.addOrUpdateItemInVault(query.password, query.body);

      //console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      //console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getSelectedDesktopVault();
  }

  const handleDesktopsListToggleFavorite = async(event: any) => {
    const toggleResponse = await api.toggleResourceFavourite({
      resourceId: event.resourceId, 
      toggleType: event.toggleType, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    //console.log("DESKTOPS: Toggle : ", toggleResponse);

    await getDesktops();
    await getDesktopsStatuses();

    toast.success('Favorites have been updated successfully', {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
      });
  }


  // On mount: hook effect
  useEffect(() =>{

    // Fetch All Data
    getDesktops();
    getGroups();
    getProfiles();
    getProjects();
    getRegions();
    getSelectedDesktopNotes();
    // getSelectedDesktopVault();

    // Reset Desktop List Delete
    setDesktopsListDeleteObject(null)
  },[id]);


  
  helpers.useInterval(() => {
    getDesktopsStatuses();

  }, 1000 * 10);


  //console.log("DESKTOPS: USER KEYS FROM CONTEXT: ", auth.userKeys);


  if(id){
    // Check if Selected desktop state value is not null
    // Render Selected desktop with success res object as props, else redirect to 404 page
    
    return (
      <>
        {(selectedDesktop) && (
          <>
            <ProductDeleteModal
                show={isShowDeleteDesktopModal}
                handleClose={handleCloseDeleteDesktopModal}
                handleModalFormSubmit={handleDesktopDeleteSubmit}
                name={selectedDesktop.name}
                productType={DEFAULT_PRODUCT_TYPE}
              />

            <SelectedDesktop
              desktopDetails={selectedDesktop} 
              desktopNotes={selectedDesktopNotes}
              desktopStatus={selectedDesktopStatus}
              productType={DEFAULT_PRODUCT_TYPE}
              
              groups={groups}
              projects={projects}
              profiles={profiles}
              desktopVault={selectedDesktopVault}

              desktopVaultPasswordSubmitError={desktopVaultPasswordSubmitError}
              desktopVaultSecretEditSubmitError={desktopVaultSecretEditSubmitError}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              toggleHandlers={{
                handlePutDesktopSecurityGroupSubmit,
                handlePutDesktopProfileSubmit,
                handlePutDesktopProjectSubmit,
              }}

              mediaControlHandlers={{
                handleProductStart: handleDesktopStart,
                handleProductStop: handleDesktopStop,
                handleProductHibernate: handleDesktopHibernate,
                handleProductFullScreen: handleDesktopFullScreen,
                handleProductDelete: handleDesktopDelete,
                handleIframeMouseOver
              }}

              productNoteHandlers={{
                handleGetProductNote: handleGetDesktopNote,
                handlePutProductNote: handlePutDesktopNote,
                handlePostProductNote: handlePostDesktopNote,
                handleDeleteProductNote: handleDeleteDesktopNote
              }}

              productVaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostProductVaultPassword: handlePostDesktopVaultPassword,
                handleDeleteProductVaultPassword: handleDeleteDesktopVaultPassword,
                handleEditProductVaultPassword: handleEditDesktopVaultPassword
              }}
            />
        </>
        
        )}
      </>
    )
  } else {
    return (
      <>
      <ProductDeleteModal
        show={isShowDeleteDesktopModal}
        handleClose={handleCloseDeleteDesktopModal}
        handleModalFormSubmit={handleDesktopsListDeleteSubmit}
        name={desktopsListDeleteObject && desktopsListDeleteObject.name}
        productType={DEFAULT_PRODUCT_TYPE}
      />

      <DesktopAddModal
        show={isShowAddDesktopModal}
        submitError={addDesktopModalSubmitError}
        handleClose={handleCloseAddDesktopModal}
        handleModalFormSubmit={handleDesktopAddSubmit}
        securityGroups={groups}
        profiles={profiles}
        projects={projects}
        regions={regions}
      />

      <div>
        <Row>
          <Col md={5}><h1> Desktop Overview </h1></Col>

        </Row>
        <h5>Hello {auth.userProfile.username}, all of your desktops are shown below.</h5>
      </div>

      <Container className="ps-3 ms-0 pt-3"> 
        <div className="pt-0">
          <div>
            <Row>
              {desktops && desktops.map((desktop: any, index: number) => {
                return (
                  <HighlightedProduct key={`HR-${index}`}
                    header={desktop.name}
                    description={desktop.description}
                    linkPath={`/desktops?id=${desktop.id}`}
                    status={desktop.state}
                    product={desktop.product}
                    system={desktop.system}
                  />
                )
              })}
            </Row>
            <Row>
              <HighlightedResourceAddButton
                clickHandler={handleShowAddDesktopModal}
                header={"Add Desktop"}
                description={"Create a new desktop"}
                image={resourceAddIcon}
              />
            </Row>
            <br/>
            <Row>
              <Row>
                <div className="ps-0">
                  <h6> All Desktops </h6>
                </div>
              </Row>

              <Row>
                <DesktopsTable 
                  desktops={desktops} 
                  mediaControlHandlers={{
                    handleDesktopsListDelete: handleDesktopsListDelete,
                    handleProductsListToggleFavorite: handleDesktopsListToggleFavorite
                  }}
              />
              </Row>
            </Row>
          </div>
        </div>
      </Container>
    </>
    );
  }
};







export default Desktops;