
import React from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';

import {
  projectIcon
} from "./../../assets/images";


import { 
  ProjectContentPane,
  ProjectLinkedProductsPane,
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
} from './../';




const Project: React.FunctionComponent<any> = (props: any) => {
  const {
    projectLinkedProducts,
    projectLinkedProfiles,
    groups,
    projectDetails,
    projectNotes, 
    projectContentActiveProducts,
    handleProjectContentPaneTabsToggle,
    handleProjectLinkedProductSelected,
    handleProjectLinkedProfileSelected,
    projectContentPaneTabsActiveKey, 
    handleProjectEditSecurityGroup,
    projectNoteHandlers,

    // Vault Handlers
    projectVaultHandlers,

    // Tab Handlers


    // Modal Handlers
    projectModalHandlers
  } = props;

  //console.log("PROJECT Details : ", projectDetails);
  //console.log("PROJECT Products : ", projectLinkedProducts);
  //console.log("PROJECT Profiles : ", projectLinkedProfiles);

  // useEffect(() => {
  // }, [projectDetails.id]);

  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <img src={projectIcon} alt={"Icon"} className="width-90-PC k-mh-60"/>
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{projectDetails && projectDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{projectDetails && projectDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && projectDetails && projectDetails.security && (projectDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === projectDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProjectEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => handleProjectEditSecurityGroup(event, group)}
                        >
                            {group.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            {/* <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                {projectDetails.project  || "----"}
              </Row>
            </Col> */}
            <Col >
              
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <ProjectLinkedProductsPane
            handleProjectLinkedProductSelected={handleProjectLinkedProductSelected}
            handleProjectLinkedProfileSelected={handleProjectLinkedProfileSelected}
            products={projectLinkedProducts}
            profiles={projectLinkedProfiles}
            // Modal Controls: Project Products
            modalHandlers={projectModalHandlers}
          />
        </Col>
      </Row>

      <Row className="">
        <Col>
          <ProjectContentPane
            projectContentActiveProducts={projectContentActiveProducts}
            handleProjectContentPaneTabsToggle={handleProjectContentPaneTabsToggle}
            projectContentPaneTabsActiveKey={projectContentPaneTabsActiveKey}
            projectDetails={projectDetails}
            projectNoteHandlers={projectNoteHandlers}
            projectNotes={projectNotes}
            projectVaultHandlers={projectVaultHandlers}
            projectProducts={projectLinkedProducts}
          />
        </Col>
      </Row>
    </>
  );
};



export default Project;