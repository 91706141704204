import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container } from 'react-bootstrap';

import { authHooks, authContextHelper } from './../../utilities'
import { Code, Password, Username } from '../../components/auth'

const ForgotPassword: React.FunctionComponent = () => {

  const { code, setCode, codeIsValid } = authHooks.useValidCode('')
  const { password, setPassword, passwordIsValid } = authHooks.useValidPassword('')
  const { username, setUsername, usernameIsValid } = authHooks.useValidUsername('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = authHooks.useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(username, code, passwordConfirm)
      setReset(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <h3 className="mb-4">Forgot Password</h3>
        <Form>
          <Form.Group id="email" className="mb-4">
            <Form.Label>Email</Form.Label>
            <Code codeIsValid={codeIsValid} setCode={setCode} />
          </Form.Group>
          <Form.Group id="password" className="mb-4">
            <Form.Label>Username</Form.Label>
            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>New Password</Form.Label>
            <Password label="New Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>New Password (Confirm)</Form.Label>
            <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
          </Form.Group>
          <Button variant="primary" type="button" className="w-100" disabled={isValid} onClick={resetPassword}>
            Change Password
          </Button>
        </Form>
      </div>
    </>
  )

  const passwordReset = (
    <>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <h3 className="mb-4 text-center">Password Changed</h3>
        <p className="text-center">
          <Card.Link onClick={() => history.push('/signin')} className="text-gray-700">
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Sign In
          </Card.Link>
          {}
        </p>
      </div>
    </>
  )

  return (
    <>
      <main>
        <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link onClick={() => history.goBack()} className="text-gray-700">
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Cancel
                </Card.Link>
                {}
              </p>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                {!reset ? updatePassword: passwordReset}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}


export default ForgotPassword;