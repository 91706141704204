import React, { useContext, useEffect, useState} from 'react';
// import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { 
  AdminContentPane,
  UserAddModal,
  GroupAddModal,
  UserDeleteModal,
  GroupDeleteModal 
} from "./../../components";


import { 
  authContextHelper, 
  api, 
  // helpers
} from './../../utilities';


const AdminDashboard: React.FunctionComponent = (props: any) => {
  // const defaultAdminContentPaneTabKeys: string[] = ["admin.Users", "admin.Groups", "admin.Notifications"]
  const { setLoaded, currentHistory } = props;
  // const id = urlParameters.get("id");
  const auth = useContext(authContextHelper.AuthContext);

  const [users, setUsers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  
  
  const [isShowDeleteUserModal, setDeleteUserModalShow] = useState(false);
  const [isShowDeleteGroupModal, setDeleteGroupModalShow] = useState(false);
  const [usersListDeleteObject, setUsersListDeleteObject] = useState<any>(null);
  const [groupsListDeleteObject, setGroupsListDeleteObject] = useState<any>(null);
  const [adminListSelectedObject, setAdminListSelectedObject] = useState<any>(null);

  const [isShowAddUserModal, setAddUserModalShow] = useState(false);
  const [addUserModalSubmitError, setAddUserModalSubmitError] = useState<any>(null);
  const [editUserSubmitError, setEditUserSubmitError] = useState<any>(null);
  const [isShowAddGroupModal, setAddGroupModalShow] = useState(false);
  const [addGroupModalSubmitError, setAddGroupModalSubmitError] = useState<any>(null);
  const [editGroupSubmitError, setEditGroupSubmitError] = useState<any>(null);

  // const [profileContentPaneTabsActiveKey, setProfileContentPaneTabsActiveKey] = useState<string>("profile.Identity");


  const handleShowAddUserModal = (event: any) => {
    // event.preventDefault();
    setAddUserModalShow(true)
  };


  const handleCloseAddUserModal = (event: any) => {
    // event.preventDefault();
    setAddUserModalShow(false)
  };


  const handleUserAddSubmit = async(query: any) => {
    const sessionTokens = await auth.getApiSessionTokens();

    //console.log("Attempting New User Add ... ", query)
    
    const submitResponse =  await api.postUser({body: query.body, user: sessionTokens });

    if ( submitResponse.result === "Failed"){
      setAddUserModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      
      handleCloseAddUserModal(null);

      currentHistory.push(`/admin`);
      window.location.reload();

    } else {

      //console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse);

    }
  }

  const handleUserEditSubmit = async(query: any) => {
    const sessionTokens = await auth.getApiSessionTokens();

    //console.log("Attempting New Group Add ... ", query)
    
    const submitResponse =  await api.editUser({body: query.body, user: sessionTokens});

    //console.log("Response: ", submitResponse);

    if ( submitResponse.result === "Failed"){
      setEditUserSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){

      currentHistory.push(`/admin`);
      window.location.reload();

    } else {

      //console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse);

    }
  }


  const handleShowAddGroupModal = (event: any) => {
    // event.preventDefault();
    setAddGroupModalShow(true)
  };


  const handleCloseAddGroupModal = (event: any) => {
    // event.preventDefault();
    setAddGroupModalShow(false)
  };


  const handleGroupAddSubmit = async(query: any) => {
    const sessionTokens = await auth.getApiSessionTokens();

    //console.log("Attempting New Group Add ... ", query)
    
    const submitResponse =  await api.postSecurityGroup({body: query.body, user: sessionTokens});

    if ( submitResponse.result === "Failed"){
      setAddGroupModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){

      handleCloseAddGroupModal(null);

      currentHistory.push(`/admin`);
      window.location.reload();

    } else {

      //console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse);

    }
  }

  const handleGroupEditSubmit = async(query: any) => {
    const sessionTokens = await auth.getApiSessionTokens();

    //console.log("Attempting Edit Group  ... ", query)
    
    const submitResponse =  await api.editSecurityGroup({body: query.body, user: sessionTokens});

    //console.log("Attempting Edit Group ... ", submitResponse)

    if ( submitResponse.result === "Failed"){
      setEditGroupSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      
      currentHistory.push(`/admin`);
      window.location.reload();

    } else {

      //console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse);

    }
  }

  const handleCloseDeleteUserModal = (event: any) => {
    // event.preventDefault();
    setDeleteUserModalShow(false)
  };

  const handleCloseDeleteGroupModal = (event: any) => {
    // event.preventDefault();
    setDeleteGroupModalShow(false)
  };

  const handleAdminSelectObject = (event: any, selectedObject: any) => {
    // event.preventDefault();
    switch(selectedObject.type){
      case "USER":
        const filteredUsers = users.filter((user: any) => user.id === selectedObject.id);
        //console.log("USER CASE Filtered Users : ", filteredUsers);
        return setAdminListSelectedObject({
          type: "USER",
          content: filteredUsers[0]
        });
      
      case "GROUP":
        const filteredGroups = groups.filter((group: any) => group.id === selectedObject.id);

        return setAdminListSelectedObject({
          type: "GROUP",
          content: filteredGroups[0]
        });

      case "NOTIFICATION":
        const filteredNotifications = notifications.filter((notification: any) => notification.id === selectedObject.id);
        
        return setAdminListSelectedObject({
          type: "NOTIFICATION",
          content: filteredNotifications[0]
        });

      default:
          return
      
    }
  };


  const  handleUsersListDelete = (event: any, usersListDeleteObjectItem: any) => {
    event.preventDefault();

    setUsersListDeleteObject(usersListDeleteObjectItem);
    setDeleteUserModalShow(true);
  }


  const  handleGroupsListDelete = (event: any, groupsListDeleteObjectItem: any) => {
    event.preventDefault();

    setGroupsListDeleteObject(groupsListDeleteObjectItem);
    setDeleteGroupModalShow(true);
  }


  const handleUsersListDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();
    //console.log("delete object: ", usersListDeleteObject);
    const { result } =  await api.deleteUser({
      body: {
        username: usersListDeleteObject && usersListDeleteObject.id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteUserModal(true);
      // currentHistory.push("/profiles");
      window.location.reload();
    }
    // setLoaded(true);
  }


  const handleGroupsListDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();
    //console.log("delete object: ", usersListDeleteObject);
    const { result } =  await api.deleteSecurityGroup({
      body: {
        id: groupsListDeleteObject && groupsListDeleteObject.id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteUserModal(true);
      // currentHistory.push("/profiles");
      window.location.reload();
    }
    // setLoaded(true);
  }


  const getUsers = async() => {
    setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();

    const usersData = await api.getUsers({user: sessionTokens});

    setUsers(usersData);
    setLoaded(true);
  }

  const getGroups = async() => {
    setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();

    const groupsData = await api.getSecurityGroups({user: sessionTokens});

    setGroups(groupsData);
    setLoaded(true);
  }


  // On mount: hook effect
  useEffect(() => {

    // Fetch All Data
    getUsers();
    getGroups();
  },[]);


  //console.log("ADMIN DASH: USER KEYS FROM CONTEXT: ", auth.userKeys);
  
  return (
    <>
      <UserAddModal
        show={isShowAddUserModal}
        submitError={addUserModalSubmitError}
        handleClose={handleCloseAddUserModal}
        handleModalFormSubmit={handleUserAddSubmit}
      />
      <GroupAddModal
        show={isShowAddGroupModal}
        users={users}
        submitError={addGroupModalSubmitError}
        handleClose={handleCloseAddGroupModal}
        handleModalFormSubmit={handleGroupAddSubmit}
      />
      <UserDeleteModal
        show={isShowDeleteUserModal}
        handleClose={handleCloseDeleteUserModal}
        handleModalFormSubmit={handleUsersListDeleteSubmit}
        name={usersListDeleteObject && usersListDeleteObject.name}
      />
      <GroupDeleteModal
        show={isShowDeleteGroupModal}
        handleClose={handleCloseDeleteGroupModal}
        handleModalFormSubmit={handleGroupsListDeleteSubmit}
        name={groupsListDeleteObject && groupsListDeleteObject.name}
      />
      <div>
        <Row>
          <Col md={3}><h1> Administration </h1></Col>
          <Col md={2}>
            
          </Col>
        </Row>
        <h5>Platform settings and user control.</h5>
      </div>

      <br/>

      <div className="ps-3 ms-0"> 
        <div className="pt-2">
          <Row>
            <div className="ps-0">
              <AdminContentPane
                users={users}
                groups={groups}
                notifications={notifications}
                selectedObject={adminListSelectedObject}
                editUserSubmitError={editUserSubmitError}
                editGroupSubmitError={editGroupSubmitError}
                handleAdminSelectObject={handleAdminSelectObject}
                handleShowAddUserModal={handleShowAddUserModal}
                handleShowAddGroupModal={handleShowAddGroupModal}
                handleGroupEditSubmit={handleGroupEditSubmit}
                handleUserEditSubmit={handleUserEditSubmit}
                mediaControlHandlers={{
                  handleUsersListDelete: handleUsersListDelete,
                  handleGroupsListDelete: handleGroupsListDelete
                }}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};



export default AdminDashboard;