import React, { useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col, Table, Card, Button, OverlayTrigger, Tooltip as ReactTooltip } from 'react-bootstrap';
import { PolarArea } from 'react-chartjs-2';
import { Editor } from '@tinymce/tinymce-react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import {
  addItemBtn,
  resourceAddIcon,
  productMediaDeleteIcon,
  horizontalDotsIcon,
  windowsIcon,
  modalUbuntuIcon,
  modalKaliIcon,

} from "./../../assets/images";

import {
  ProfileLinkedProductButton,
  ProjectLinkedProductButton,
  ProjectProfileButton,
  AdminGroupsContentEditFormPane,
  AdminUsersContentEditFormPane,
  NotePanelList,
  PasswordPanelList,
  ProfileAccountPasswordFormPane,
  ResourceAccountPasswordFormPane,
  ProfileLinkedProductContentPane,
  ProjectLinkedProductContentPane,
  ProductToolsPasswordItem,
  DropdownAddButton
} from "./../../components";


import { 
  api,
  helpers,
  authContextHelper,
  variables 
} from './../../utilities';



const ProfileIdentityContentTab: React.FunctionComponent<any> = (props:any) => {
  const { profileDetails, profileProducts } = props;
  //console.log("profileLinkedProducts : ", profileLinkedProducts)
  // console.log("profile.Identity tab: ", props)
  // onClick={() => fullScreen(`fullscreen-${desktopDetails.id}`)}
  const [selectedProfileLinkedProductsFps, setSelectedProfileLinkedProductsFps] = useState<any>([]);

  //const [fpCompareMatrix, setfpCompareMatrix] = useState<any>();
  const [fpCompareMatrix, setfpCompareMatrix] = useState<Map<any, any>>(new Map());

  let fpObjects: any[] = [];
  type matchJsonObject = {
    // Define the structure of your JSON object properties
    // Replace `KeyType` and `ValueType` with the actual types used in your JSON object
    // For example, if the key is a string and the value is a number, use `string` and `number` respectively
    [KeyType: string]: any;
  };

  const [cardIndex, setIndex] = useState(0);
  const weekAgoInMilliseconds = Date.now() - (1000 * 60 * 60 * 24 * 7);

  const randomColors = (count: number) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.6)`;
      colors.push(color);
    }
    return colors;
  };

  const footer = (tooltipItems: any) => {
    let sum = 0;
    
    //console.log("LOG tooltipItems : ", tooltipItems);
    tooltipItems.forEach(function(tooltipItem: { parsed: { r: number; }; }) {
      sum += tooltipItem.parsed.r;
    });
    if (sum === 0){return 'Unique Identifier'}
    else {return 'Matches within profile: ' + sum;}
    
  };

  const checkInit = (objectOne: any, objectTwo: any, matchedObject: any, object: any, key: string): any => {
    
    if (objectOne === objectTwo) {
      if (matchedObject) {
        const updatedObject:matchJsonObject = {
          ...matchedObject,
          value: objectOne,
          count: matchedObject.count + 1,
          products: [
            ...matchedObject.products,
            { name: object.product.name, id: object.product.id }, // Add new product
          ],
        };
       
        return (updatedObject);
      } else {
        const updatedObject:matchJsonObject = {
          value: objectOne,
          key: key,
          count: 1,
          products: [{ name: object.product.name, id: object.product.id } ]
        }
        return (updatedObject);
      }
    } else {
      //We dont have a fingerprint object yet... initialise it
      let total = 0;
      if (objectOne === objectTwo) { total = 1}
      const updatedObject:matchJsonObject = {
        value: objectOne,
        key: key,
        count: total,
        products: [ ]
      };
      
      return (updatedObject);
    }
  };

  const PolarAreaChart: React.FunctionComponent<any> = (props:any) => {
    const { object, classObject } = props;
    // Register the radialLinear scale
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    // Need to put an initial negative value so we can see the zero (unique)
    // identifiers, otherwise they dont show up
    let labelArrary: any[] = ["default"];
    let dataArray: any[] = [-1];
    object.forEach((value: any, key: string) => {
      labelArrary.push(value.key+':'+value.value);
      //if(value.count === 0){dataArray.push(value.count+0.1);}
      //else {dataArray.push(value.count);}
      dataArray.push(value.count);
    });
  
    const data = {
      labels: labelArrary,
      datasets: [
        {
          data: dataArray,
          backgroundColor: randomColors(dataArray.length),
        },
      ],
    };

    const options = {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            footer: footer,
          }
        },
        title: {
          display: false,
          text: 'Polar Area Chart',
        },
        legend: {
          display: false,
          position: 'bottom' as const, // Use specific type for position
          align: 'start' as const, // Use specific type for align
        },
      },
      scales: {
        r: {
          angleLines: {
            display: false,
          },
          grid: {
            display: false,
            color: 'transparent'
          },
          ticks: {
            display: false,
            suggestedMin: -10,
            beginAtZero: false,
          },
        },
      },
    };
  
    return <PolarArea data={data} options={options} className='k-mmh-270' />;
  };

  const FpTable: React.FunctionComponent<any> = (props:any) => {
    const { object } = props;
    let tableRows:any = [];

    object.forEach((value: any, key: string) => {
      //console.log('FpTable value : ', value);
      if (typeof value.value === "boolean"){
        if (value.value) {tableRows.push({"key": value.key, "count": value.count, "value": "True"});}
        else {tableRows.push({"key": value.key, "count": value.count, "value": "False"}); }
      }else {
        tableRows.push({"key": value.key, "count": value.count, "value": value.value});
      }
    });

    const totalWidth = 200; // Total width in pixels
    const totalCount = tableRows.reduce((sum: any, row: { count: any; }) => sum + row.count, 0); // Calculate total count

    return (
      <Table>
        <thead>
          <tr>
            <th className="ps-0 pt-1 pb-1 pe-1">Identifier</th>
            <th className="ps-0 pt-1 pb-1 pe-1">Count</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row: any) => {
            const percentage = (row.count / totalCount) * 100; // Calculate the percentage based on the total count
            //let width = row.count === 0 ? 10 : (totalWidth * percentage) / 100; // Set a minimum width of 5 when count is zero
            let width = ((totalWidth * percentage) / 100); 
            if (width < 10){width = 10} // set the smallest width of the bar to 10

            return (
              <tr>
                <td className="ps-0 pt-1 pb-1 pe-1">{row.key}</td>
                <td className="ps-0 pt-1 pb-1 pe-1">
                 
                    <div
                      className="k-fp-bar-container"
                      style={{
                        width: `${totalWidth}px`,
                      }}
                    >
                      <OverlayTrigger
                          placement="right"
                          overlay={<ReactTooltip>{row.value} </ReactTooltip>}
                      >
                        <div
                          style={{
                            backgroundColor: row.count === 0 ? 'transparent' : '#93bdff',
                            width: `${width}px`, // Adjust the scaling factor as needed
                            borderRadius: '5px',
                          }}
                        >
                          { row.count === 0 ? 
                            <span className='black-txt-10 ps-1'>{row.count}</span>: 
                            <span className='white-txt-10 ps-1'>{row.count}</span> 
                          }
                        </div>
                      </OverlayTrigger>
                    </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleGetProductFP = async(query: any) => {
    let response;
    try {
      response = await api.getResourcePrinterLogs(query);
      if (response && response.status === 200){
        return {result: 'Success', data: response, id: query.resourceId};
      } else {
        return {result: 'Error', id: query.resourceId, status: response.status}
      }
    } catch (err) {
      return {result: 'Error', data: err, id: query.resourceId}
    }
  }

  const processProfileProducts = async(products: any) => {
    let object: any[] = [];
    const promises = [];
    for(const product of products){
      let printer = handleGetProductFP({
        resourceId: product.id,  
        start: weekAgoInMilliseconds, 
        max: 10,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
      });
      promises.push(printer);
      printer.then((response: any) => {
        //console.log('PROMISE RESPONSE !!!! : Success and Data not null  : ', response)
        if (response.result === 'Success' && response.data.data != null){
          if (response.data.data.printer !== undefined) {
            const newObj = { 
              "id": response.id,
              "fp": response.data.data.printer
            };
            //console.log("NEW Object PRODUCT : ",newObj );
            fpObjects.push(newObj);
            //console.log("UPDATED fpObjects : ",fpObjects ); 
          }
        } else {
            // Handle error
            //console.log(`Error: ${response}`);
        }
      }, (reject: any) => {
          //console.log(`Error: ${reject}`);
      });
    }
    
    await Promise.all(promises);

    let newLinkedProducts: any[] = [];
   // console.log("Looping through fpObjects : ", fpObjects)
    for (const obj of fpObjects) {
      //console.log(`LOOPING fpObjects ${obj.id}:${obj.fp}`);
      let product = profileProducts.find((x: { id: any; }) => x.id === obj.id);
      if (product) {
          newLinkedProducts.push({id: obj.id, fp: obj.fp, product: product});
      }
    }
    //console.log("END newLinkedProducts : ", newLinkedProducts);
    setSelectedProfileLinkedProductsFps(newLinkedProducts);
    
  }

  const analyseFPs = (data: any) => {
    //console.log("LOG: analyseFPs: ", data);
    let fpCompare = new Map<string, any>([]);
    let fpResults: any[] = [];

    data.forEach((obj: any) => {
      //Itterate through each product
      if (obj.fp && Array.isArray(obj.fp) && obj.fp.length > 0) {
        let mostRecentFp = obj.fp[0];
        //Itterate through all fp's
        obj.fp.forEach((fp: any, fpIndex: number) => {
          const fpInstance = fpCompare.get(fp.timestamp);
          if (!fpInstance) {
            fpCompare.set(fp.timestamp, { 
              timestamp: fp.timestamp,
              product: obj.product,
              fp: {pHash: fp.message.fingerprint, vHash: fp.message.volatileFingerprint, 
                persistent: fp.message.details.persistent, volatile: fp.message.details.volatile
              },
              matches: new Map<string, matchJsonObject>(),
            });
          }
          //Itterate through all products again
          data.forEach((objTwo: any, fpIndexTwo: number) => {
            //Only we only want to compare different dev fp's
            if (obj.id != objTwo.id ) {
              //Make sure we have an array of fp's in the devoce to compare
              if (objTwo.fp && Array.isArray(objTwo.fp) && objTwo.fp.length > 0) {
                //Itterate through all the fps of the other product
                objTwo.fp.forEach((fpTwo: any, fpIndexThree: number) => {
                  const fpObject = fpCompare.get(fp.timestamp);
                  //console.log("objTwo.fp.forEach", fp.message.fingerprint)
                  fpObject.matches.set("fingerprint", checkInit(
                    fp.message.fingerprint,
                    fpTwo.message.fingerprint,
                    fpObject.matches.get("fingerprint"),
                    objTwo,
                    "fingerprint"
                  )  as matchJsonObject );
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.os
                  fpObject.matches.set("system.os", checkInit(
                    fp.message.details.persistent["system.os"],
                    fpTwo.message.details.persistent["system.os"],
                    fpObject.matches.get("system.os"),
                    objTwo,
                    "system.os"
                  ) as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.gpu
                  fpObject.matches.set("system.gpu", checkInit(
                    fp.message.details.persistent["system.gpu"],
                    fpTwo.message.details.persistent["system.gpu"],
                    fpObject.matches.get("system.gpu"),
                    objTwo,
                    "system.gpu"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.cores
                  fpObject.matches.set("system.cores", checkInit(
                    fp.message.details.persistent["system.cores"],
                    fpTwo.message.details.persistent["system.cores"],
                    fpObject.matches.get("system.cores"),
                    objTwo,
                    "system.cores"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.screen
                  fpObject.matches.set("system.screen", checkInit(
                    fp.message.details.persistent["system.screen"],
                    fpTwo.message.details.persistent["system.screen"],
                    fpObject.matches.get("system.screen"),
                    objTwo,
                    "system.screen"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.battery
                  fpObject.matches.set("system.battery", checkInit(
                    fp.message.details.persistent["system.battery"],
                    fpTwo.message.details.persistent["system.battery"],
                    fpObject.matches.get("system.battery"),
                    objTwo,
                    "system.battery"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.touch
                  fpObject.matches.set("system.touch", checkInit(
                    fp.message.details.persistent["system.touch"],
                    fpTwo.message.details.persistent["system.touch"],
                    fpObject.matches.get("system.touch"),
                    objTwo,
                    "system.touch"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent system.worker
                  fpObject.matches.set("browser.worker", checkInit(
                    fp.message.details.persistent["browser.worker"],
                    fpTwo.message.details.persistent["browser.worker"],
                    fpObject.matches.get("browser.worker"),
                    objTwo,
                    "browser.worker"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);
                  
                  //Persistent browser.agent
                  fpObject.matches.set("browser.simpleagent", checkInit(
                    fp.message.details.persistent["browser.agent"],
                    fpTwo.message.details.persistent["browser.agent"],
                    fpObject.matches.get("browser.agent"),
                    objTwo,
                    "browser.simpleagent"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent browser.plugins
                  fpObject.matches.set("browser.plugins", checkInit(
                    fp.message.details.persistent["browser.plugins"],
                    fpTwo.message.details.persistent["browser.plugins"],
                    fpObject.matches.get("browser.plugins"),
                    objTwo,
                    "browser.plugins"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent browser.language
                  fpObject.matches.set("browser.language", checkInit(
                    fp.message.details.persistent["browser.language"],
                    fpTwo.message.details.persistent["browser.language"],
                    fpObject.matches.get("browser.language"),
                    objTwo,
                    "browser.language"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Persistent browser.name
                  fpObject.matches.set("browser.name", checkInit(
                    fp.message.details.persistent["browser.name"],
                    fpTwo.message.details.persistent["browser.name"],
                    fpObject.matches.get("browser.name"),
                    objTwo,
                    "browser.name"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile volatileFingerprint
                  fpObject.matches.set("volatileFingerprint", checkInit(
                    fp.message.volatileFingerprint,
                    fpTwo.message.volatileFingerprint,
                    fpObject.matches.get("volatileFingerprint"),
                    objTwo,
                    "volatileFingerprint"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.width
                  fpObject.matches.set("browser.width", checkInit(
                    fp.message.details.volatile["browser.width"],
                    fpTwo.message.details.volatile["browser.width"],
                    fpObject.matches.get("browser.width"),
                    objTwo,
                    "browser.width"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.height
                  fpObject.matches.set("browser.height", checkInit(
                    fp.message.details.volatile["browser.height"],
                    fpTwo.message.details.volatile["browser.height"],
                    fpObject.matches.get("browser.height"),
                    objTwo,
                    "browser.height"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.agent
                  fpObject.matches.set("browser.agent", checkInit(
                    fp.message.details.volatile["browser.agent"],
                    fpTwo.message.details.volatile["browser.agent"],
                    fpObject.matches.get("browser.agent"),
                    objTwo,
                    "browser.agent"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.cookies
                  fpObject.matches.set("browser.cookies", checkInit(
                    fp.message.details.volatile["browser.cookies"],
                    fpTwo.message.details.volatile["browser.cookies"],
                    fpObject.matches.get("browser.cookies"),
                    objTwo,
                    "browser.cookies"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.canvas
                  fpObject.matches.set("browser.canvas", checkInit(
                    fp.message.details.volatile["browser.canvas"],
                    fpTwo.message.details.volatile["browser.canvas"],
                    fpObject.matches.get("browser.canvas"),
                    objTwo,
                    "browser.canvas"
                  )as matchJsonObject);
                  //fpCompare.set(fp.timestamp, fpObject);

                  //Volatile browser.track
                  fpObject.matches.set("browser.track", checkInit(
                    fp.message.details.volatile["browser.track"],
                    fpTwo.message.details.volatile["browser.track"],
                    fpObject.matches.get("browser.track"),
                    objTwo,
                    "browser.track"
                  )as matchJsonObject);

                  fpCompare.set(fp.timestamp, fpObject);

                });
              }
            }
          });
        });
      }
    });
    //console.log("LOG: Final fpCompare", fpCompare);
    setfpCompareMatrix(fpCompare);
  }

  useEffect(() => {
    if (profileProducts) {
      processProfileProducts(profileProducts);
    }
  }, [profileProducts]);

  
  useEffect(() => {
      analyseFPs(selectedProfileLinkedProductsFps);
  }, [selectedProfileLinkedProductsFps]);

  useEffect(() => {
    // Perform any side effects or computations based on fpCompareMatrix here
    // This code will run whenever fpCompareMatrix changes
  }, [fpCompareMatrix]);


  return (
    <>
      <Row className='mt-2'>
        {Array.from(fpCompareMatrix.entries()).map(([key, value]: [any, any]) => {
          try {
            if (value.matches.get("fingerprint")) {
              
                return (
                  <Col className="col-6" key={key}>
                    <Card className='k-brd-transparent mb-3'>
                      <Card.Header className='p-1 ms-1 k-brd-transparent'>
                        <Row xs="auto" className='ms-0 me-0'>
                          <Col className="ps-1 col-auto me-auto">
                            <Row>
                              <h5 className='mt-2 mb-0'>
                                {value.product.system === 'windows' && <img src={windowsIcon} alt='Windows Icon' className='k-mmh-15 mb-1 me-1' />}
                                {value.product.system === 'ubuntu' && <img src={modalUbuntuIcon} alt='Ubuntu Icon' className='k-mmh-15 mb-1 me-1' />}
                                {value.product.system === 'kali' && <img src={modalKaliIcon} alt='Kali Icon' className='k-mmh-15 mb-1 me-1' />}
                                {value.product.name} 
                              </h5>
                            </Row>
                            <Row>
                              <span className='card-subheader-10'>
                                {new Date(value.timestamp).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: '2-digit' }) + ' \\ '}
                                Fingerprint
                              </span>
                            </Row>
                          </Col>
                          <Col className='pe-0'><Button variant="outline-light" className="btn-no-shadow k-brd-transparent" size="sm"><img src={horizontalDotsIcon} alt={"Icon"} className="addIcon dropdown-tab-icon"/></Button></Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className='p-1 ms-1'>
                        <Row xs="auto" className='ms-0 me-0'> 
                          <Col className="ps-1 col-auto me-auto col-6">
                            <Row>
                                        {value.matches.get("fingerprint").count && value.matches.get("fingerprint").count === 0 ? (
                                          value.matches.get("volatileFingerprint").count === 0 ? (
                                            <h6 className='mb-0'>Unique Fingerprint</h6>
                                          ) : (
                                            <h6 className='mb-0'>Volatile Fingerprint Match</h6>
                                          )
                                        ) : (
                                          <OverlayTrigger
                                              placement="bottom"
                                              overlay={<ReactTooltip>
                                                { value.matches.get("fingerprint").products && value.matches.get("fingerprint").products.map((dev: any, ind: number) => {
                                                  //console.log("FULL MATCH : ",dev, ind)
                                                  return(<span className='white-txt-15'>{dev.name}</span>);
                                                })}
                                              </ReactTooltip>}
                                          >
                                            <h6 className='mb-0'>Full Fingerprint Match</h6>
                                          </OverlayTrigger>
                                        )}
                                        <PolarAreaChart
                                          object={value.matches}
                                        />
                            </Row>
                          </Col>
                          <Col className="ps-1 col-auto me-auto col-6 k-mmh-300 k-of-y-scroll noShowScroll ">
                            <FpTable
                              object={value.matches}
                            />
                          </Col>
                        </Row>
                      </Card.Body> 
                    </Card>
                  </Col>
                );
              
            } else {
              return (<></>);
            }
          } catch (error) {
            // Handle the error when accountPassword.url is not a valid URL
            //console.log("ERROR: FP", error);
            return (<></>);
          }
        })}
      </Row>
    </>
  );
}

const ProfileVaultContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    profileVault, 
    vaultHandlers,
    preSelectedSecretId,
    setPreSelectedSecretId,
    vaultSecretEditSubmitError 
  } = props;

  const [secrets, setSecrets] = useState<any>([]);
  const [selectedSecret, setSelectedSecret] = useState<any>(null)


  const getVaultSecrets = async() => {
    const vaultHeader = profileVault && await profileVault.getDecryptedVaultHeader();
    //console.log("Vault Header: ", vaultHeader)
    const secretsData = vaultHeader.listSecrets();
    //console.log("Secxrets DATAS : ", secretsData)
    if(secretsData){
      setSecrets(secretsData);

      if(preSelectedSecretId){
        let secretData = secrets.find((secret: any)  => secret.secretId === preSelectedSecretId);
        
        secretData.password = profileVault && await profileVault.getAndDecryptItemFromVault(preSelectedSecretId);
        
        setSelectedSecret(secretData);
  
        //console.log("Selected Previous Secret Found!: ", secretData)
      } else {
        setSelectedSecret(null);
      }
    }
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    // getNotes(profileID);
    // getVault(profileID);
    
    // setSelectedSecret(null);
    getVaultSecrets();

  }, [profileVault]);


  const handleCreateSecret = async(event: any) => {
    event.preventDefault();
    // await handleProfileAddPassword({body: '<h1> New Note </h1>', id: profileID, headline: 'New Note', title: 'New Note'});
    await vaultHandlers.handlePostProfileVaultPassword()
    
  }

  const handleDeleteSecret = async(event: any, secretId: any) => {
    event.preventDefault();
  
    await vaultHandlers.handleDeleteProfileVaultPassword({
      body: {
        secretId
      }
    });
  }

  const handleSelectSecret = async(event: any, secretId: string ) => {
    event.preventDefault()
    //console.log("SEcret Selected: ", secretId);

    if(secrets){
      let secretData = secrets.find((secret: any)  => secret.secretId === secretId);
      secretData.password = profileVault && await profileVault.getAndDecryptItemFromVault(secretId);
      
      setSelectedSecret(secretData);

      //console.log("Selected Secret Found!: ", secretData)
    }
  }


  //console.log("Profile Content Tab: Vault: ", profileVault );
  //console.log("Profile Content Tab: Secrets: ", secrets );
  //console.log("Selected Secret: ", selectedSecret)

  return (
    <>
      <Row>
        <Col className="col-3 mw-310">
          <Card className='hmh-100vh-280px'>
            <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto">Passwords</Col>
                <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={handleCreateSecret}/></Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-3'>
                { secrets && secrets.map((secret: any, index: number) => {
                  //console.log("PASSWORD LOOPER : ", secret);
                  return (
                    <Row className="m-0">
                      <Col className="p-0">
                      <PasswordPanelList key={`prodPassword-${index}`}
                        id={secret.secretId}
                        title={secret.title}
                        utsModified={secret.modified}
                        headline={secret.headline}
                        passwordUrl={secret.url}
                        passwordID={secret.id}
                        edit='false'
                        handlePasswordSelect={handleSelectSecret}
                        handlePasswordDelete={handleDeleteSecret}
                      />
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-9 hmh-100vh-280px">
          <Card className='hmh-100vh-280px'>
            {selectedSecret && selectedSecret.secretId ? 
            <>
              <Card.Header className='p-1'>
                <Row xs="auto" className='ms-0 me-0'>
                  <Col className="ps-1 col-auto me-auto">Password</Col>
                  {/* <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={(event: any ) => console.log("password saved...")}/></Col> */}
                </Row>
              </Card.Header>
              <Card.Body className='p-0 k-card-3'>
                <Row className='m-0 pt-3 justify-content-center'>
                  <Col className='m-0 col-6 justify-content-center'>
                    {/* <Row className='m-0 justify-content-center pb-3'><Col className='col-6 m-0 p-0 pe-3 k-txt-aln-e'><img src="./../images/twitter.com.png" alt={"Icon"} className="k-mmh-50"/> </Col><Col className='col-6 m-0 p-0 pt-2 header-black-22'>{vaultNew.title}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>username </Col><Col className='col-6 m-0 p-0'> {vaultNew.username}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>password </Col><Col className='col-6 m-0 p-0'> {vaultNew.password}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>website </Col><Col className='col-6 m-0 p-0'> {vaultNew.url}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>OTP/2FA </Col><Col className='col-6 m-0 p-0'> {vaultNew.otp}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>modified on</Col><Col className='col-6 m-0 p-0'>{vaultNew.modified}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>created on</Col><Col className='col-6 m-0 p-0'>{vaultNew.created}</Col></Row> */}
                    <ProfileAccountPasswordFormPane
                      accountPassword={selectedSecret}
                      editAccountPasswordSubmitError={vaultSecretEditSubmitError}
                      handleAccountPasswordEditSubmit={vaultHandlers.handleEditProfileVaultPassword}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </>
            :
              < Card.Header>
                Please Select A Password
              </Card.Header>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
}

const ResourceVaultContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    vault, 
    vaultHandlers,
    // preSelectedSecretId,
    // setPreSelectedSecretId,
    // vaultSecretEditSubmitError 
  } = props;

  const [secrets, setSecrets] = useState<any>([]);
  const [selectedSecret, setSelectedSecret] = useState<any>(null)


  const getVaultSecrets = async() => {
    const vaultHeader = vault && await vault.getDecryptedVaultHeader();
    //console.log("Vault Header: ", vaultHeader)
    const secretsData = vaultHeader.listSecrets();
    //console.log("Secxrets DATAS : ", secretsData)
    if(secretsData){
      setSecrets(secretsData);

      if(vaultHandlers && vaultHandlers.preSelectedSecretId){
        let secretData = secrets.find((secret: any)  => secret.secretId === vaultHandlers.preSelectedSecretId);
        
        secretData.password = vault && await vault.getAndDecryptItemFromVault(vaultHandlers.preSelectedSecretId);
        
        setSelectedSecret(secretData);
  
        //console.log("Selected Previous Secret Found!: ", secretData)
      } else {
        setSelectedSecret(null);
      }
    }
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    getVaultSecrets();

  }, [vault]);


  const handleCreateSecret = async(event: any) => {
    event.preventDefault();
    
    await vaultHandlers.handlePostVaultPassword()
    
  }

  const handleDeleteSecret = async(event: any, secretId: any) => {
    event.preventDefault();
  
    await vaultHandlers.handleDeleteVaultPassword({
      body: {
        secretId
      }
    });
  }

  const handleSelectSecret = async(event: any, secretId: string ) => {
    event.preventDefault()
    //console.log("SEcret Selected: ", secretId);

    if(secrets){
      let secretData = secrets.find((secret: any)  => secret.secretId === secretId);
      secretData.password = vault && await vault.getAndDecryptItemFromVault(secretId);
      
      setSelectedSecret(secretData);

      //console.log("Selected Secret Found!: ", secretData)
    }
  }


  //console.log("Resource Content Tab: Vault: ", vault );
  //console.log("Resource Content Tab: Secrets: ", secrets );
  //console.log("Selected Secret: ", selectedSecret)

  return (
    <>
      <Row>
        <Col className="col-3 mw-310">
          <Card className='hmh-100vh-280px'>
            <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto">Passwords</Col>
                <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={handleCreateSecret}/></Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-3'>
                { secrets && secrets.map((secret: any, index: number) => {
                  //console.log("PASSWORD LOOPER : ", secret);
                  return (
                    <Row className="m-0">
                      <Col className="p-0">
                      <PasswordPanelList key={`prodPassword-${index}`}
                        id={secret.secretId}
                        title={secret.title}
                        utsModified={secret.modified}
                        headline={secret.headline}
                        passwordUrl={secret.url}
                        passwordID={secret.id}
                        edit='false'
                        handlePasswordSelect={handleSelectSecret}
                        handlePasswordDelete={handleDeleteSecret}
                      />
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-9 hmh-100vh-280px">
          <Card className='hmh-100vh-280px'>
            {selectedSecret && selectedSecret.secretId ? 
            <>
              <Card.Header className='p-1'>
                <Row xs="auto" className='ms-0 me-0'>
                  <Col className="ps-1 col-auto me-auto">Password</Col>
                  {/* <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={(event: any ) => console.log("password saved...")}/></Col> */}
                </Row>
              </Card.Header>
              <Card.Body className='p-0 k-card-3'>
                <Row className='m-0 pt-3 justify-content-center'>
                  <Col className='m-0 col-6 justify-content-center'>
                    {/* <Row className='m-0 justify-content-center pb-3'><Col className='col-6 m-0 p-0 pe-3 k-txt-aln-e'><img src="./../images/twitter.com.png" alt={"Icon"} className="k-mmh-50"/> </Col><Col className='col-6 m-0 p-0 pt-2 header-black-22'>{vaultNew.title}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>username </Col><Col className='col-6 m-0 p-0'> {vaultNew.username}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>password </Col><Col className='col-6 m-0 p-0'> {vaultNew.password}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>website </Col><Col className='col-6 m-0 p-0'> {vaultNew.url}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>OTP/2FA </Col><Col className='col-6 m-0 p-0'> {vaultNew.otp}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>modified on</Col><Col className='col-6 m-0 p-0'>{vaultNew.modified}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>created on</Col><Col className='col-6 m-0 p-0'>{vaultNew.created}</Col></Row> */}
                    <ResourceAccountPasswordFormPane
                      accountPassword={selectedSecret}
                      editAccountPasswordSubmitError={vaultHandlers.vaultSecretEditSubmitError}
                      handleAccountPasswordEditSubmit={vaultHandlers.handleEditVaultPassword}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </>
            :
              < Card.Header>
                Please Select A Password
              </Card.Header>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
}


const ProfileNoteContentTab: React.FunctionComponent<any> = (props:any) => {
  const {noteHandlers, profileNotes } = props;

  const editorRef = useRef<any>(null);
  // const [noteData, setNoteData] = useState<any>([]);

  const handleSaveNote = async() => {
    const noteBody = editorRef.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);
    // if (noteTmp.split('\n').length > 1) {
    //   const secondLine = noteTmp.split('\n')[1].substring(0, 35);
    //   await handleProfileEditNote({body: noteBody,  id: profileID, headline: secondLine, title: firstLine, noteid: editorRef.current.id})
    // } else {
    //   await handleProfileEditNote({body: noteBody,  id: profileID, headline: '', title: firstLine, noteid: editorRef.current.id})
    // }
    await noteHandlers.handlePutProfileNote({
      body: noteBody,  
      headline: secondLine, 
      title: firstLine, 
      noteId: editorRef.current.id,
      editorRef
    });
    // getNotes(profileID);
    noteHandlers.handleGetProfileNotes()
  }

  return (
    <>
      <Row>
        <Col className="col-3 mw-310">
          <Card className='hmh-100vh-280px'>
            <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto">Notes</Col>
                <Col className=''>
                  <img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={(event: any) => noteHandlers.handlePostProfileNote({...event, editorRef})}/>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-3'>
                { profileNotes && profileNotes.map((note: any, index: number) => {
                  return (
                    <Row className="m-0">
                      <Col className="p-0">
                        <NotePanelList key={`prodNote-${note.id}`}
                          header={note.title}
                          utsModified={note.modified}
                          headline={note.headline}
                          noteUrl={note.url}
                          noteID={note.id}
                          edit='false'
                          handleNoteClick={(event: any) => noteHandlers.handleGetProfileNote({...event, editorRef})}
                          handleNoteDelete={(event: any) => noteHandlers.handleDeleteProfileNote(note.id)}
                        />
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-9 hmh-100vh-280px">
          <Editor
            id='profile-selectednote-editor'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt12: any, editor: any) => editorRef.current = editor}
            //initialValue="<h1>Welcome to your editor</h1>"
            init={{
              height: '100%',
              menubar: true,
              //inline: true,
              //toolbar: false,
              format: 'raw',
              branding: false,
              promotion: false,
              plugins: 'save anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
              toolbar: 'save | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
              block_unsupported_drop: false,
              //toolbar: 'save undo redo | bold italic underline',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 'strong { font-weight: 900; }',
              //toolbar_location: 'top',
              save_onsavecallback: () => {
                handleSaveNote();
              }
            }}
          />
        </Col>
      </Row>
    </>

  );
}

const ProfileLinkedProductContentTab: React.FunctionComponent<any> = (props:any) => {
  let { productDetails } = props;
  const auth = useContext(authContextHelper.AuthContext);
  // console.log("profile.Product tab: ", props)
  const [productState, setProductState] = useState<string>(productDetails.state && productDetails.state && productDetails.state.toUpperCase())


  const getProfileProductStatus = async () => {
    const desktopData = await api.getProduct({
      body: {
        id: productDetails.id
      }, 
    });

    setProductState( desktopData && desktopData.state && desktopData.state.toUpperCase());
    
    productDetails.state = desktopData && desktopData.state && desktopData.state.toUpperCase();
  }

  const handleIframeMouseOver = async(event: any) => {
    event.preventDefault();
    const iframeDocument = document.getElementById(event.target.id);
    iframeDocument?.focus();
  }

  const handleDesktopStart = async(event: any, query: any) => {
    event.preventDefault();

    await api.startProduct({
      body: {
        id: productDetails.id
      }
    });
  }

  const  handleDesktopStop = async (event: any) => {
    event.preventDefault();

    await api.stopProduct({
      body: {
        id: productDetails.id
      }
    });

  }

  const  handleDesktopFullScreen = (event: any) => {
    event.preventDefault();
  }

  const  handleDesktopHibernate = async(event: any) => {
    event.preventDefault();
    const sessionTokens = await auth.getApiSessionTokens();

    await api.hibernateProduct({
      body: {
        id: productDetails.id
      }, 
      user: sessionTokens
    });

  }

  const  handleDesktopDelete = (event: any) => {
    event.preventDefault();

    // setDeleteDesktopModalShow(true)
    //console.log("TBD: Delete not implemented => go to product's page")
    return
  }

  helpers.useInterval(() => {
    getProfileProductStatus();

  }, 1000 * 10);
  
  return (

    <>
      <ProfileLinkedProductContentPane
        productDetails={productDetails}
        productType={"product"}
        mediaControlHandlers={{
          handleIframeMouseOver: handleIframeMouseOver
        }}
      />
    </>

  );
}

const ProfileLinkedProductOverviewTab: React.FunctionComponent<any> = (props:any) => {
  const { products, handleProfileLinkedProductSelected } = props;
  //console.log("profile.Product overview tab: ", props)
  
  //<div key={`profile-linked-products-item-${index}`}
  //  style={{ cursor: "pointer"}}
  //  onClick={(event) => handleProfileLinkedProductSelected(event, object.id)}
  ///>

  return (
    <ul className='ps-0 mt-2 mb-0 horizontalList'>
      {products.map((product: any, index: number) => {
        return (
        <li key={`pr-dd-${index}`} className='horizontalListItem' 
          // style={{ cursor: "pointer"}}
          // onClick={(event) => handleProfileLinkedProductSelected(event, object.id)}
          >
          <ProfileLinkedProductButton
              product={product}

              handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
          />
        </li>
        )
      })}
    </ul>
  );
}

const ProjectDetailContentTab: React.FunctionComponent<any> = (props:any) => {
  const { projectDetails } = props;

  return (
    <>
      <Row className='pt-4'>
        <Col className="col-12">
          <Card className=''>
          <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto">Activity</Col>
                <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon"/></Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-2'>
              <Table>
                
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const ProjectNoteContentTab: React.FunctionComponent<any> = (props:any) => {
  const { noteHandlers, projectNotes } = props;

  const editorRef = useRef<any>(null);

  const saveNote = async() => {
    const noteBody = editorRef.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    await noteHandlers.handlePutProjectNote({
      body: noteBody,  
      headline: secondLine, 
      title: firstLine, 
      noteId: editorRef.current.id,
      editorRef
    });
    
    await noteHandlers.handleGetProjectNotes();
  }


  return (
    <>
      <Row>
        <Col className="col-3 mw-310">
          <Card className='hmh-100vh-280px'>
            <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto" >Notes</Col>
                <Col className=''>
                  <img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={(event: any) => noteHandlers.handlePostProjectNote({...event, editorRef})}/>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-3'>
                { projectNotes && projectNotes.map((note: any, index: number) => {
                  return (
                    <Row className="m-0">
                      <Col className="p-0">
                        <NotePanelList key={`prodNote-${note.id}`}
                          header={note.title}
                          utsModified={note.modified}
                          headline={note.headline}
                          noteUrl={note.url}
                          noteID={note.id}
                          edit='false'
                          handleNoteClick={(event: any) => noteHandlers.handleGetProjectNote({...event, editorRef})}
                          handleNoteDelete={(event: any) => noteHandlers.handleDeleteProjectNote({...event, editorRef})}
                        />
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-9 hmh-100vh-280px">
          <Editor
            id='project-selectednote-editor'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt12: any, editor: any) => editorRef.current = editor}
            //initialValue="<h1>Welcome to your editor</h1>"
            init={{
              height: '100%',
              menubar: true,
              //inline: true,
              //toolbar: false,
              format: 'raw',
              branding: false,
              promotion: false,
              plugins: 'save anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
              toolbar: 'save | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
              block_unsupported_drop: false,
              //toolbar: 'save undo redo | bold italic underline',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 'strong { font-weight: 900; }',
              //toolbar_location: 'top',
              save_onsavecallback: () => {
                saveNote();
              }
            }}
          />
        </Col>
      </Row>
    </>

  );
}

const ProjectLinkedProductContentTab: React.FunctionComponent<any> = (props:any) => {
  let { product } = props;
  const auth = useContext(authContextHelper.AuthContext);
  // console.log("project.Product tab: ", props)
  const [productState, setProductState] = useState<string>(product.state && product.state && product.state.toUpperCase())


  const getProjectProductStatus = async () => {
    const sessionTokens = await auth.getApiSessionTokens();

    const desktopData = await api.getProduct({
      body: {
        id: product.id
      }, 
      user: sessionTokens
    });

    setProductState( desktopData && desktopData.state && desktopData.state.toUpperCase());
    
    product.state = desktopData && desktopData.state && desktopData.state.toUpperCase();
  }

  const handleIframeMouseOver = async(event: any) => {
    event.preventDefault();
    const iframeDocument = document.getElementById(event.target.id);
    iframeDocument?.focus();
  }

  helpers.useInterval(() => {
    getProjectProductStatus();

  }, 1000 * 10);
  
  return (

    <>
      <ProjectLinkedProductContentPane
        productDetails={product}
        productType={"product"}
        mediaControlHandlers={{
          handleIframeMouseOver: handleIframeMouseOver
        }}
      />
    </>

  );
}

const ProjectLinkedProductOverviewTab: React.FunctionComponent<any> = (props:any) => {
  const { products, handleProjectLinkedResourceSelected } = props;
  //console.log("project.Products overview tab: ", props)


  return (
    <ul className='ps-0 mt-2 mb-0 horizontalList'>
      {products.map((object: any, index: number) => {
        return (
        <li key={`pr-dd-${index}`} className='horizontalListItem' 
         style={{ cursor: "pointer"}}
          onClick={(event) => handleProjectLinkedResourceSelected(event, object.id)}>
          <ProjectLinkedProductButton 
              header={object.name}
              description={object.description}
              status={object.state}
              product={object.product}
              system={object.system}
          />
        </li>
        )
      })}
    </ul>
  );
}

const ProjectProfilesOverviewTab: React.FunctionComponent<any> = (props:any) => {
  const { profiles, handleProjectResourceSelected } = props;
  //console.log("project.Profiles overview tab: ", props)


  return (
    <ul className='ps-0 mt-2 mb-0 horizontalList'>
      {profiles && profiles.map((object: any, index: number) => {
        return (
        <li 
          key={`pr-dd-${index}`} 
          className='horizontalListItem' 
          style={{ cursor: "pointer"}}
          // onClick={(event) => handleProjectLinkedResourceSelected(event, object.id)}
          >
          <Link to={`/profiles?id=${object.id}`}>
            <ProjectProfileButton 
              header={object.name}
              description={object.description}
            />
          </Link>
        </li>
        )
      })}
    </ul>
  );
}

const AdminUsersContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    users,
    groups,
    selectedUser, 
    mediaControlHandlers,
    editUserSubmitError,
    handleUserEditSubmit,
    handleShowAddUserModal, 
    handleAdminSelectObject 
  } = props;

  // console.log("Admin Users Cont TAB: Selected USER : ", selectedUser);

  return (
    <>
      <Row >
        <Col className="col-6 p-0 ">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="card shadow minh-400-mh-100vh">
                <Row className='m-0 pt-2'>
                  <Col className="col-11">
                    <h5>Users</h5>
                  </Col>
                  <Col className="col-1">
                    <button type="button" onClick={handleShowAddUserModal} className="k-generic-btn-nobg">
                      <img src={resourceAddIcon} alt={"Admin Users Add Buttton"}/>
                    </button>
                  </Col>
                </Row>
                <Row className='k-of-y-scroll m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead className=''>
                      <tr>
                        <th>Username</th>
                        <th>Display Name</th>
                        <th>Role</th>
                        <th>MFA</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='k-t-body-scroll-admin'>
                      {users && users.map((user: any, index: number) => {
                        return (
                          <tr key={`admin-users-${index}`}>
                            <td 
                              onClick={(event)=> handleAdminSelectObject(event, {type: "USER", id: user.id})}
                              style={{ cursor: "pointer"}}
                            >
                              {user.id}
                            </td>
                            <td>{user.id}</td>
                            <td>{user.role}</td>
                            <td>Yes</td>
                            <td> 
                              <Row>
                                
                                <Col md={1}></Col>
                                <Col md={1}>
                                  <div style={{ cursor: "pointer"}} onClick={(event: any)=>{ mediaControlHandlers.handleUsersListDelete(event, {id: user.id, name: user.id})}}>
                                    <span>
                                      <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>


        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedUser && selectedUser.type === "USER") ? (
                <>
                  <div className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>User Detail</h5>
                      </Col>
                    </Row>
                    <AdminUsersContentEditFormPane
                      userContent={selectedUser.content}
                      groups={groups}
                      editUserSubmitError={editUserSubmitError}
                      handleUserEditSubmit={handleUserEditSubmit}
                    />
                  </div> 
                </>
                )
                :
                (<>
                  <div className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                        <Col className='col-12'>
                          <h5>Select a User</h5>
                        </Col>
                      </Row>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

const AdminGroupsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    groups,
    users, 
    selectedGroup, 
    mediaControlHandlers,
    editGroupSubmitError,
    handleGroupEditSubmit,
    handleShowAddGroupModal, 
    handleAdminSelectObject 
  } = props;

  //console.log("Admin Group Cont TAB: Selected Group : ", selectedGroup);
  // console.log("profile.Identity tab: ", props)

  return (
    <>
      <Row >
        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="card shadow minh-400-mh-100vh">
                <Row className='m-0 pt-2'>
                  <Col className="col-11">
                    <h5>Groups</h5>
                  </Col>
                  <Col className="col-1">
                    <button onClick={handleShowAddGroupModal} type="button" className="k-generic-btn-nobg">
                      <img src={resourceAddIcon} alt={"Admin Groups Add Buttton"}/>
                    </button>
                  </Col>
                </Row>
                <Row className='k-of-y-scroll m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th colSpan={2}>Description</th>
                        <th colSpan={2}>Created On</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='k-t-body-scroll-admin'>
                    {groups && groups.map((group: any, index: number) => {
                        return (
                          <tr key={`admin-groups-${index}`}>
                            <td 
                              onClick={(event)=> handleAdminSelectObject(event, {type: "GROUP", id: group.id})}
                              style={{ cursor: "pointer"}}
                            >
                              {group.name}
                            </td>
                            <td colSpan={2}>{group.description}</td>
                            <td colSpan={2}>---</td>
                            <td> 
                              <Row>
                                
                                <Col md={1}></Col>
                                <Col md={1}>
                                  <div style={{ cursor: "pointer"}} onClick={(event: any)=>{ mediaControlHandlers.handleGroupsListDelete(event, {id: group.id, name: group.name})}}>
                                    <span>
                                      <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}                    
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
            {(selectedGroup && selectedGroup.type === "GROUP") ? (
                <>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>Group Detail</h5>
                      </Col>
                    </Row>
                    <AdminGroupsContentEditFormPane
                      groupContent={selectedGroup.content}
                      users={users}
                      editGroupSubmitError={editGroupSubmitError}
                      handleGroupEditSubmit={handleGroupEditSubmit}
                    />

                  </div>  
                </>)
                :
                (<>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                        <Col className='col-12'>
                          <h5>Select Group</h5>
                          </Col>
                      </Row>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

const AdminNotificationsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { notifications, selectedNotification, handleAdminSelectObject } = props;

  // console.log("Admin Notifications Cont TAB: Selected Notifications : ", selectedNotification);
  // console.log("profile.Identity tab: ", props)

  return (
    <>
      <Row>
        <Col className="col-7 ">
          <Row>
            <Col className="col-12">
              <div className="card shadow">
                <br/>
                <Row>
                  <Col className="col-11">
                    <h4>System Notifications</h4>
                  </Col>
                  {/* <Col className="col-1">
                    <img src={resourceAddIcon} alt={"Admin Groups Add Buttton"}/>
                  </Col> */}
                </Row>
                <br/>
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Severity</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications && notifications.map((notification: any, index: number) => {
                      return (
                        <tr key={`admin-groups-${index}`}>
                          <td 
                            onClick={(event)=> handleAdminSelectObject(event, {type: "NOTIFICATION", id: notification.id})}
                            style={{ cursor: "pointer"}}
                          >
                            {notification.date}
                          </td>
                          <td colSpan={2}>{notification.description}</td>
                          <td>INFO</td>
                          <td colSpan={2}>Admin</td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>03/03/2022</td>
                      <td colSpan={2}>New security group created - RED-1</td>
                      <td>INFO</td>
                      <td colSpan={2}>Admin</td>
                    </tr>
                    <tr>
                      <td>RED-1</td>
                      <td colSpan={2}>Password reset request for Henry</td>
                      <td>ALERT</td>
                      <td colSpan={2}>Henry</td>
                    </tr>
                    
                  </tbody>
                </Table>

              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-5">
          <Row>
            <Col className="col-12">
            {(selectedNotification && selectedNotification.type === "NOTIFICATION")? (
                <>
                  <div  className="card shadow">
                    <h4>SELECTED NOTIFICATION stuff here</h4>
                    <br/>
                    <Row>
                      <Col className="col-4">
                        Note Name: {selectedNotification.content.name}
                      </Col>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                  </div> 
                </>)
                :
                (<>
                  <div  className="card shadow">
                    <h4>Select Notification</h4>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}


const ProductToolsVaultTab: React.FunctionComponent<any> = (props: any)  => {

  const {
    productDetails, 
    vault,
    preSelectedSecretId,
    vaultSecretEditSubmitError,
    vaultHandlers,
  } = props;

  const [secrets, setSecrets] = useState<any>([]);
  const [selectedSecret, setSelectedSecret] = useState<any>(null)


  const getVaultSecrets = async() => {
    //console.log("Product Toold Vault: ", vault);
    // if(vault){
      
    // }
    const vaultHeader = vault && await vault.getDecryptedVaultHeader();
      //console.log("Vault Header: ", vaultHeader)
      const secretsData = vaultHeader.listSecrets();
      //console.log("Secxrets DATAS : ", secretsData)
      if(secretsData){
        setSecrets(secretsData);

        if(preSelectedSecretId){
          let secretData = secrets.find((secret: any)  => secret.secretId === preSelectedSecretId);
          
          secretData.password = vault && await vault.getAndDecryptItemFromVault(preSelectedSecretId);
          
          setSelectedSecret(secretData);
    
          //console.log("Selected Previous Secret Found!: ", secretData)
        } else {
          setSelectedSecret(null);
        }
      }
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    getVaultSecrets();

  }, [vault]);

  // console.log("Vault: ")
  const handleCreateSecret = async(event: any) => {
    event.preventDefault();
    // await handleProfileAddPassword({body: '<h1> New Note </h1>', id: profileID, headline: 'New Note', title: 'New Note'});
    await vaultHandlers.handlePostProductVaultPassword()
    
  }

  const handleDeleteSecret = async(event: any, secretId: any) => {
    event.preventDefault();
    //console.log("retreiving product secret: ....", secretId)
    await vaultHandlers.handleDeleteProductVaultPassword({
      body: {
        secretId
      }
    });
  }

  const handleSelectSecret = async(event: any, secretId: string ) => {
    // event.preventDefault();
    //console.log("SEcret Selected: ", secretId);

    if(secrets){
      let secretData = secrets.find((secret: any)  => secret.secretId === secretId);
      secretData.password = vault && await vault.getAndDecryptItemFromVault(secretId);
      
      setSelectedSecret(secretData);

      //console.log("Selected Secret Found!: ", secretData)
    }
  }


  //console.log("PRODUCT Vault Tab: Vault: ", vault );
  //console.log("PRODUCT Vault Tab: Secrets: ", secrets );
  //console.log("PRODUCT Vault Tab: Selected Secret: ", selectedSecret);
  //console.log("PRODUCT Vault Tab: Desktop Details: ", productDetails);

  return (
      <>
        <Row className="width-100-PC m-0">
          <Col className="ms-4 ps-0 pe-0">
            <Row className="header-black-22">Passwords</Row>
            <Row className="subheader-black-22 mt-m10">Manage your Secrets</Row>
          </Col>
          <Col className="ms-0 mt-2 ms-2 p-0">
            <DropdownAddButton
              btnClass={"ps-0 pe-0 pt-0 ml-95"}
              clickHandler={handleCreateSecret}
            />
          </Col>
        </Row>
        <div className="scrolling-topcontainer">
          <div className="scrolling-container pt-2">
            { secrets && secrets.map((secret: any, index: number) => {
              
              return (
                <Row className="m-0" key={`secret-index-${index}`}>
                  <Col className="pb-0 ps-3 pe-3">
                    <ProductToolsPasswordItem 
                      onClick={(event: any) => handleSelectSecret(event, secret.secretId)}
                      key={`prodPass-${secret.secretId}`}
                      secret={secret}

                      selectedSecret={selectedSecret}
                      vaultSecretEditSubmitError={vaultSecretEditSubmitError}

                      handlePasswordSelect={handleSelectSecret}
                      handlePasswordDelete={handleDeleteSecret}
                      handlePasswordEdit={vaultHandlers.handleEditProductVaultPassword}
                    />
                    </Col>
                </Row>
              )
            })}
          </div>
        </div>
      </>
  )
}


export {
  // Profile Content
  ProfileIdentityContentTab,
  ProfileNoteContentTab,
  ProfileVaultContentTab,
  ProfileLinkedProductContentTab,
  ProfileLinkedProductOverviewTab,
  ProjectProfilesOverviewTab,

  // Project Content
  ProjectDetailContentTab,
  ProjectNoteContentTab,
  ProjectLinkedProductContentTab,
  ProjectLinkedProductOverviewTab,
  ResourceVaultContentTab,

  // Admin Content
  AdminUsersContentTab,
  AdminGroupsContentTab,
  AdminNotificationsContentTab,

  // Product 
  ProductToolsVaultTab
};