import React from 'react'
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

// import './App.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/scss/volt.scss"; // Import Volt Styling
import 'react-toastify/dist/ReactToastify.css'; // Toaster Styling

import { authContextHelper } from './utilities'
import { ScrollToTop } from './components'

import {
  AuthenticatedLayout,
  OpenLayout
} from './layouts'


const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <ScrollToTop/>
    <ToastContainer />
    <authContextHelper.AuthProvider>
      <authContextHelper.AuthIsSignedIn>
        <AuthenticatedLayout/>
      </authContextHelper.AuthIsSignedIn>
      <authContextHelper.AuthIsNotSignedIn>
        <OpenLayout />
      </authContextHelper.AuthIsNotSignedIn>
    </authContextHelper.AuthProvider>
  </BrowserRouter>
)

// export default withRouter(App)
export default App
