// Here are more informations about the scroll restoration of React Router
// https://reactrouter.com/web/guides/scroll-restoration

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Image, Form, Col } from 'react-bootstrap';

import { helpers, variables } from "./../../utilities"

import {
  windowsRunningIcon,
  windowsStoppedIcon,
  windowsHibernatedIcon,
  windowsBuildingIcon,

  ubuntuRunningIcon,
  ubuntuStoppedIcon,
  ubuntuHibernatedIcon,
  ubuntuBuildingIcon,

  kaliRunningIcon,
  kaliStoppedIcon,
  kaliHibernatedIcon,
  kaliBuildingIcon,

  browserChromeRunningIcon,
  browserChromeStoppedIcon,
  browserChromeHibernatedIcon,
  browserChromeBuildingIcon,
  
  browserEdgeRunningIcon,
  browserEdgeStoppedIcon,
  browserEdgeHibernatedIcon,
  browserEdgeBuildingIcon,

  browserFirefoxRunningIcon,
  browserFirefoxStoppedIcon,
  browserFirefoxHibernatedIcon,
  browserFirefoxBuildingIcon,

  modalWindowsIcon,
  modalUbuntuIcon,
  modalMacIcon,
  modalKaliIcon,

  browserChromeIcon,
  browserEdgeIcon,
  browserFirefoxIcon,

  vtcJitsiIcon,
  vtcJitsiHibernatedIcon,
  vtcJitsiBuildingIcon,
  vtcJitsiRunningIcon,
  vtcJitsiStoppedIcon,

  chatRocketIcon,
  chatRocketHibernatedIcon,
  chatRocketBuildingIcon,
  chatRocketRunningIcon,
  chatRocketStoppedIcon,

  profileIcon
} from "./../../assets/images";


const ScrollToTop: React.FunctionComponent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  return null;
}


const DesktopLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { system, classOverride } = props;

  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "windows":
            return (
              <img src={modalWindowsIcon} alt={"Windows Logo"} className={classOverride}/>
            );

          case "ubuntu":
            return (
              <img src={modalUbuntuIcon} alt={"Ubuntu Logo"} className={classOverride}/>
            );

          case "mac":
            return (
              <img src={modalMacIcon} alt={"Mac OS Logo"} className={classOverride}/>
            );

          case "kali":
            return (
              <img src={modalKaliIcon} alt={"Kali OS Logo"} className={classOverride}/>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const BrowserLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { system } = props;
  //console.log("browser logo image props: ", props)

  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "chrome":
            return (
              <img src={browserChromeIcon} alt={"Chrome Logo"} className="k-detailHeaderImg"/>
            );

          case "firefox":
            return (
              <img src={browserFirefoxIcon} alt={"Firefox Logo"} className="k-detailHeaderImg"/>
            );

          case "edge":
            return (
              <img src={browserEdgeIcon} alt={"Edge Logo"} className="k-detailHeaderImg"/>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const CommunicateLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { system, classOverride } = props;
  //console.log("LOG SYSTEM IS: ", system)
  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "jitsi":
            return (
              <img src={vtcJitsiIcon} alt={"Jitsi Logo"} className={classOverride}/>
            );

          case "rocket.chat":
            return (
              <img src={chatRocketIcon} alt={"RocketChat Logo"} className={classOverride}/>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const ProductLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { productType, system, classOverride } = props;
  //console.log("browser logo image props: ", props)

  return (
    <>
      {(() => {
        switch (productType && productType.toLowerCase()) {
          case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
            return (
              <DesktopLogoImage
                system={system}
                classOverride={classOverride}
              />
            );

          case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
            return (
              <BrowserLogoImage
                system={system}
                classOverride={classOverride}
              />
            );
            
          case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
            return (
              <ChatLogoImage
                system={system}
                classOverride={classOverride}
              />
            );
          
          case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
            return (
              <VtcLogoImage
                system={system}
                classOverride={classOverride}
              />
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const VtcLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { system, classOverride } = props;

  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "jitsi":
            return (
              <img src={vtcJitsiIcon} alt={"Jitsi Logo"} className={classOverride}/>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const ChatLogoImage: React.FunctionComponent<any> = (props:any) => {
  const { system, classOverride } = props;

  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "rocket.chat":
            return (
              <img src={chatRocketIcon} alt={"RocketChat Logo"} className={classOverride}/>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const DesktopStatusImageWindows: React.FunctionComponent<any> = (props:any) => {
  const { status } = props;
  //console.log("desktop windows logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={windowsHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={windowsHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={windowsRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={windowsRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={windowsStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={windowsStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={windowsBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const DesktopStatusImageUbuntu: React.FunctionComponent<any> = (props:any) => {
  const { status } = props;
  //console.log("desktop ubuntu logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={ubuntuHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={ubuntuHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={ubuntuRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={ubuntuRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={ubuntuStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={ubuntuStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={ubuntuBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const DesktopStatusImageKali: React.FunctionComponent<any> = (props:any) => {
  const { status } = props;
  //console.log("desktop ubuntu logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={kaliHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={kaliHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={kaliRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={kaliRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={kaliStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={kaliStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={kaliBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const BrowserStatusImageEdge: React.FunctionComponent<any> = (props:any) => {
  const { status} = props;
  //console.log("Browser edge image logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={browserEdgeHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={browserEdgeHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={browserEdgeRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={browserEdgeRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={browserEdgeStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={browserEdgeStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={browserEdgeBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}


const BrowserStatusImageFirefox: React.FunctionComponent<any> = (props:any) => {
  const { status} = props;
  //console.log("Browser edge image logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={browserFirefoxHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={browserFirefoxHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={browserFirefoxRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={browserFirefoxRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={browserFirefoxStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={browserFirefoxStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={browserFirefoxBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}


const BrowserStatusImageChrome: React.FunctionComponent<any> = (props:any) => {
  const { status} = props;
  //console.log("Browser edge image logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={browserChromeHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={browserChromeHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={browserChromeRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={browserChromeRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={browserChromeStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={browserChromeStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={browserChromeBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}


const DesktopStatusImage: React.FunctionComponent<any> = (props:any) => {
  const { system, status} = props;
  //console.log("Desktop status image logging props: ", props)

  return (
    <>
      {(() => {
        switch (system && system.toLowerCase()) {
          case "windows":
            return (
              <DesktopStatusImageWindows
              status={status}
              />
            );
          case "ubuntu":
            return (
              <DesktopStatusImageUbuntu
              status={status}
              />
            );
          case "kali":
            return (
              <DesktopStatusImageKali
              status={status}
              />
            );

          default:
            return null
        }
      })()}
    </>
  );
}


const BrowserStatusImage: React.FunctionComponent<any> = (props:any) => {
  const { system, status} = props;
  //console.log("Browser status image logging props: ", props)
  return (
    <>
      {(() => {
        switch (system) {
          case "edge":
            return (
              <BrowserStatusImageEdge
              status={status}
              />
            );

          case "chrome":
            return (
              <BrowserStatusImageChrome
              status={status}
              />
            );

          case "firefox":
            return (
              <BrowserStatusImageFirefox
              status={status}
              />
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const ChatStatusImage: React.FunctionComponent<any> = (props:any) => {
  const { system, status} = props;
  //console.log("Browser status image logging props: ", props)
  return (
    <>
      {(() => {
        switch (system) {
          case "rocket.chat":
            return (
              <ChatStatusImageRocketchat
              status={status}
              />
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const VtcStatusImage: React.FunctionComponent<any> = (props:any) => {
  const { system, status} = props;
  //console.log("Browser status image logging props: ", props)
  return (
    <>
      {(() => {
        switch (system) {
          case "jitsi":
            return (
              <VtcStatusImageJitsi
              status={status}
              />
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const ChatStatusImageRocketchat: React.FunctionComponent<any> = (props:any) => {
  const { status} = props;
  //console.log("Browser edge image logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={chatRocketHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={chatRocketHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={chatRocketRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={chatRocketRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={chatRocketStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={chatRocketStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={chatRocketBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const VtcStatusImageJitsi: React.FunctionComponent<any> = (props:any) => {
  const { status} = props;
  //console.log("Browser edge image logging props: ", props)

  return (
    <>
      {(() => {
        switch (status && status.toLowerCase()) {
          case "hibernation":
            return (
              <Image src={vtcJitsiHibernatedIcon}></Image>
            );

          case "hibernating":
            return (
              <Image src={vtcJitsiHibernatedIcon}></Image>
            );

          case "running":
            return (
              <Image src={vtcJitsiRunningIcon}></Image>
            );

          case "starting":
            return (
              <Image src={vtcJitsiRunningIcon}></Image>
            );

          case "stopped":
            return (
              <Image src={vtcJitsiStoppedIcon}></Image>
            );

          case "stopping":
            return (
              <Image src={vtcJitsiStoppedIcon}></Image>
            );

          case "building":
            return (
              <Image src={vtcJitsiBuildingIcon}></Image>
            );

          default:
            return null
        }
      })()}
    </>
  );
}

const HighlightedProductImage: React.FunctionComponent<any> = (props:any) => {
  const { product, system, status} = props;

  //console.log("Product image logging props: ", props)

  return (
    <>
      {(() => {
        switch (product && product.toLowerCase()) {
          case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
            return (
              <DesktopStatusImage
                system={system}
                status={status}
              />
            );

          case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
            return (
              <BrowserStatusImage
                system={system}
                status={status}
              />
            );
          
          case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
            return (
              <ChatStatusImage
                system={system}
                status={status}
              />
            );
          
          case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
            return (
              <VtcStatusImage
                system={system}
                status={status}
              />
            );
            

          default:
            return null
        }
      })()}
    </>
  );
};

const HighlightedProfileImage: React.FunctionComponent<any> = (props:any) => {
  // const { product, system, status} = props;

  //console.log("profile image logging props: ", props)

  return (
    <>
      <Image src={profileIcon}></Image>
    </>
  );
};

const AdminGroupAddUsersDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: "pointer"}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));

const SelectGroupDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: "pointer"}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));

const SelectProjectDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: "pointer"}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));

const SelectProfileDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: "pointer"}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));

const AdminGroupAddUsersDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          style={{border: "none"}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <hr/>
        <div style={{ maxHeight: "100px", overflowX: "auto"}}>
        {
          React.Children.toArray(children).filter(
            (child: any) => {
              return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
            },
          )
        }
        </div>
      </div>
    );
  },
);


const SelectGroupDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-auto pt-0 k-inputText12"
          style={{border: "none"}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <hr className="m-0"/>
        <div className="k-mh-200 k-of-x-auto k-inputText12">
        {
          React.Children.toArray(children).filter(
            (child: any) => {
              return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
            },
          )
        }
        </div>
      </div>
    );
  },
);

const SelectProjectDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-auto pt-0 k-inputText12"
          style={{border: "none"}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <hr className="m-0"/>
        <div className="k-mh-200 k-of-x-auto k-inputText12">
        {
          React.Children.toArray(children).filter(
            (child: any) => {
              return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
            },
          )
        }
        </div>
      </div>
    );
  },
);

const SelectProfileDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-auto pt-0 k-inputText12"
          style={{border: "none"}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <hr className="m-0"/>
        <div className="k-mh-200 k-of-x-auto k-inputText12">
        {
          React.Children.toArray(children).filter(
            (child: any) => {
              return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
            },
          )
        }
        </div>
      </div>
    );
  },
);



const TwoDigitCountdown: React.FunctionComponent = () =>  {
  const DEFAULT_OTP_COUNTDOWN_TIME = 30;
  const [seconds, setSeconds] = useState<number>(DEFAULT_OTP_COUNTDOWN_TIME);
  const [code, setCode] = useState<number>(Math.floor(100000 + Math.random() * 900000));
  const circumference = 2 * Math.PI * 9;
  const strokeDashoffset = circumference - (seconds / 30) * circumference;
  
  // useEffect(() => {
    
  //   // if (code === 0) {
  //   //   setCode(Math.floor(100000 + Math.random() * 900000));
  //   // }

  // }, []);
  const updateCodeTimer = () => {
    // When the timer reaches zero, we reset it back to 30 again
    if (seconds < 1 ) {
      setSeconds(DEFAULT_OTP_COUNTDOWN_TIME);
      setCode(Math.floor(100000 + Math.random() * 900000));

    } else {
      // Decrease the timer every second
      setSeconds(seconds - 1);
    }
  }

  helpers.useInterval(updateCodeTimer, 1000);


  return (
    <>
      <Col className="col-5 pe-0 nowrap password-details-0">OTP:</Col>
      <Col className="col-3 password-details-1">
        {code}
      </Col>
      <Col className="password-details-1 ps-0 mt-m2">
        <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        style={{ margin: 'auto' }}
      >
        <circle
          stroke="#90A3DB"
          strokeWidth="1.5"
          fill="transparent"
          r="9"
          cx="10"
          cy="10"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
        />
        <text
          x="10"
          y="14"
          textAnchor="middle"
          fontFamily="Arial"
          fontSize="11"
          fill="black"
        >
          {seconds}
        </text>
      </svg>
      </Col>
      
    </>
    
  )
}

// const ReturnConditionalDateTime = (unixtimeStamp:number) => {
//   const currentTime = new Date();
//   const passedTime = new Date(unixtimeStamp * 1000);

//   if (currentTime.getDate() === passedTime.getDate()) {
//     const time = `${passedTime.getHours().toString().padStart(2, '0')}:${passedTime.getMinutes().toString().padStart(2, '0')}`;
//     return(time);
//   } else {
//     const date = `${passedTime.getMonth() + 1}/${passedTime.getDate()}/${passedTime.getFullYear()}`;
//     return (date);
//   }
// };


const TwoDigitCountdownDropdown: React.FunctionComponent = () =>  {
  const DEFAULT_OTP_COUNTDOWN_TIME = 30;
  const [seconds, setSeconds] = useState<number>(DEFAULT_OTP_COUNTDOWN_TIME);
  const [code, setCode] = useState<number>(Math.floor(100000 + Math.random() * 900000));
  const circumference = 2 * Math.PI * 9;
  const strokeDashoffset = circumference - (seconds / 30) * circumference;
  
  // useEffect(() => {
    
  //   // if (code === 0) {
  //   //   setCode(Math.floor(100000 + Math.random() * 900000));
  //   // }

  // }, []);
  const updateCodeTimer = () => {
    // When the timer reaches zero, we reset it back to 30 again
    if (seconds < 1 ) {
      setSeconds(DEFAULT_OTP_COUNTDOWN_TIME);
      setCode(Math.floor(100000 + Math.random() * 900000));

    } else {
      // Decrease the timer every second
      setSeconds(seconds - 1);
    }
  }

  helpers.useInterval(updateCodeTimer, 1000);


  return (
    <>
      <Col className="col-3 pe-0 nowrap password-details-0">OTP:</Col>
      <Col className="col-3 password-details-1 ps-4 ">
        {code}
      </Col>
      <Col className="password-details-1 ps-2 mt-m2">
        <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        style={{ margin: 'auto' }}
      >
        <circle
          stroke="#90A3DB"
          strokeWidth="1.5"
          fill="transparent"
          r="9"
          cx="10"
          cy="10"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
        />
        <text
          x="10"
          y="14"
          textAnchor="middle"
          fontFamily="Arial"
          fontSize="11"
          fill="black"
        >
          {seconds}
        </text>
      </svg>
      </Col>
      
    </>
    
  )
}





export {
  ScrollToTop,
  HighlightedProductImage,
  HighlightedProfileImage,
  BrowserLogoImage,
  DesktopLogoImage,
  ChatLogoImage,
  VtcLogoImage,
  CommunicateLogoImage,
  ProductLogoImage,
  AdminGroupAddUsersDropdownMenu,
  AdminGroupAddUsersDropdownToggle,
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
  SelectProjectDropdownMenu,
  SelectProjectDropdownToggle,
  SelectProfileDropdownMenu,
  SelectProfileDropdownToggle,
  TwoDigitCountdown,
  TwoDigitCountdownDropdown
}