import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk/global';
import { DEFAULT_CONFIG } from './../variables';
import { api, bsgCrypto } from '.';


// const userPoolId = process.env.REACT_APP_USERPOOL_ID
// const clientId = process.env.REACT_APP_CLIENT_ID

const userPoolId = DEFAULT_CONFIG["COGNITO_USERPOOL_ID"]
const clientId = DEFAULT_CONFIG["CONGNITO_CLIENT_ID"]
// const cookieDomain = DEFAULT_CONFIG["DOMAIN_URL"]

Amplify.configure({
  Auth: {
    region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
    userPoolId: userPoolId, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: clientId, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    
    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
        // - Cookie domain (only required if cookieStorage is provided)
        // domain: `.${window.location.hostname}`,
        domain: `${window.location.hostname}`,
        // domain: "localhost",
        // (optional) - Cookie expiration in days
        expires: 1,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'none',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
    },

    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
});

//Amplify.Logger.LOG_LEVEL = "DEBUG";


let currentUser: any;


export async function signInWithEmail(username: string, password: string, newPassword?: string, userMfaCode?: string, userMfaSetupCode?: string) {
  return new Promise(async function (resolve, reject) {
    try {

      if(newPassword){
        currentUser = await Auth.completeNewPassword(
          currentUser, // the Cognito User Object
          newPassword, 
        )

        // resolve(currentUser);
        reject({
          code: "UserRequiresSignout",
        });

      } else if(userMfaSetupCode){
        // //console.log("ATTEMPTING MFA SETUP: User? ", currentUser)
        // //console.log("ATTEMPTING MFA SETUP: MFA Code? ", userMfaSetupCode)
  
        await Auth.verifyTotpToken(
          currentUser, // Return object from Auth.signIn()
          userMfaSetupCode, // Confirmation code
        );
        // //console.log("TOTP VERIFY: ", totpVerification);

        await Auth.setPreferredMFA(currentUser, 'TOTP');
        // //console.log("TOTP PREFERRED: ", totpPreferedSetting);

        reject({
          code: "UserRequiresSignout",
        });
      } else if(userMfaCode){
       
        currentUser = await Auth.confirmSignIn(
          currentUser, // Return object from Auth.signIn()
          userMfaCode, // Confirmation code
          "SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        resolve(currentUser);

      } else {

        currentUser = await Auth.signIn(username, password);
        
        if (currentUser && currentUser.challengeName === 'MFA_SETUP') {

          const code = await Auth.setupTOTP(currentUser);

          reject({
            code: "UserRequiresMfaSetup",
            mfaSetup: {
              qrString: "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=KURO_APP",
              code: code
            }
          });

        } else if (
          (currentUser && currentUser.challengeName === 'SMS_MFA') ||
          (currentUser && currentUser.challengeName === 'SOFTWARE_TOKEN_MFA')
        ) {

          reject({code: "UserRequiresMfaCode"});

        } else if (currentUser && currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        
          reject({code: "UserRequiresNewPassword"});
  
        } else {
  
          resolve(currentUser);
  
        }
      }

    } catch (error) {
        reject(error);
    }}
  )
}

export async function verifyUserKeys(validatedUsername: string, password: string) {
  
  const apiResponse = await api.getUserKeys({username: validatedUsername});
  ////console.log("Get Keys Response: ...", apiResponse)

  if (apiResponse.hasError) {
    ////console.log("Error getting user keys : ", apiResponse);
    throw new Error("Error getting or generating keys for user");
  } else if (apiResponse.hasKeys) {
    ////console.log("Has Keys...", apiResponse);

    // const parsed: bsgCrypto.SerializedCryptoData = JSON.parse(apiResponse.data);
    // //console.log("Has Keys : 1", parsed);

    const userKeys: bsgCrypto.CryptoData = await bsgCrypto.CryptoData.deserialize(apiResponse.data);
    //console.log("Has Keys : 2", userKeys);

    return userKeys;
  } else if (!apiResponse.hasKeys) {
    // generate keys
    //console.log("Generating Keys...", apiResponse);
    
    const userKeys = await bsgCrypto.generateUsersKeyPair(validatedUsername, password);
    //console.log("Generating Keys : 1", userKeys);

    const serializedKeys: bsgCrypto.SerializedCryptoData = await userKeys.serialize();
    //console.log("Generating Keys : 2", serializedKeys);

    const toSendToServer = JSON.stringify(serializedKeys);
    //console.log("Generating Keys : 3", toSendToServer);

    const updateResponse = await api.putUserKeys({username: validatedUsername, keys: toSendToServer});
    //console.log("Generating Keys : 4", updateResponse);

    return userKeys;
  } 
}

export const getDeserialisedUserKeys = async( query: any) => {
  const apiResponse = await api.getUserKeys({username: query.username});
  //console.log("Get Keys Response: ...", apiResponse);

  if (apiResponse.hasKeys) {
    //console.log("Has Keys...", apiResponse);

    // const parsed: bsgCrypto.SerializedCryptoData = JSON.parse(apiResponse.data);
    // //console.log("Has Keys : 1", parsed);

    const userKeys: bsgCrypto.CryptoData = await bsgCrypto.CryptoData.deserialize(apiResponse.data);
    //console.log("Has Keys : 2", userKeys);

    return userKeys;
  }
}

export async function signOut() {
  if (currentUser) {
    currentUser.signOut()
    
    await Auth.signOut({ global: true });

    await Auth.Credentials.clear();

    if (AWS.config.credentials) {
      // AWS.config.credentials?.clearCachedId(); // this is the clear session
      AWS.config.credentials = new AWS.CognitoIdentityCredentials(); // this is the new instance after the clear
    } 

    localStorage.clear();
    // retrieve all cookies
    let Cookies = document.cookie.split(';');
    // set past expiry to all cookies .... need to do this to ensure we are clearing all
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            let p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
    
  }
}

export function getCurrentUser() {
  return currentUser
}


export async function getSession() {
  if (!currentUser) {
    currentUser = await Auth.currentAuthenticatedUser();
  }

  // await Auth.currentAuthenticatedUser();


  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function getAmplifyCurrentAthenticatedUser() {
  return await Auth.currentAuthenticatedUser();
}

export async function signUpUserWithEmail(username: string, email: string, password: string) {
  return 
  // new Promise(function (resolve, reject) {
  //   const attributeList = [
  //     new CognitoUserAttribute({
  //       Name: 'email',
  //       Value: email,
  //     }),
  //   ]

  //   userPool.signUp(username, password, attributeList, [], function (err, res) {
  //     if (err) {
  //       reject(err)
  //     } else {
  //       resolve(res)
  //     }
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}

export async function verifyCode(username: string, code: string) {
  return 
  // new Promise(function (resolve, reject) {
  //   const cognitoUser = getCognitoUser(username)

  //   cognitoUser.confirmRegistration(code, true, function (err, result) {
  //     if (err) {
  //       reject(err)
  //     } else {
  //       resolve(result)
  //     }
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}


export async function getAttributes() {
  return 
  
  // new Promise(function (resolve, reject) {
  //   currentUser.getUserAttributes(function (err: any, attributes: any) {
  //     if (err) {
  //       reject(err)
  //     } else {
  //       resolve(attributes)
  //     }
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}

export async function setAttribute(attribute: any) {
  return 
  
  // new Promise(function (resolve, reject) {
  //   const attributeList = []
  //   const res = new CognitoUserAttribute(attribute)
  //   attributeList.push(res)

  //   currentUser.updateAttributes(attributeList, (err: any, res: any) => {
  //     if (err) {
  //       reject(err)
  //     } else {
  //       resolve(res)
  //     }
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}

export async function sendCode(username: string) {
  return 
  // new Promise(function (resolve, reject) {
  //   const cognitoUser = getCognitoUser(username)

  //   if (!cognitoUser) {
  //     reject(`could not find ${username}`)
  //     return
  //   }

  //   cognitoUser.forgotPassword({
  //     onSuccess: function (res) {
  //       resolve(res)
  //     },
  //     onFailure: function (err) {
  //       reject(err)
  //     },
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}

export async function forgotPassword(username: string, code?: string, newPassword?: string) {
  return new Promise(async function (resolve, reject) {
    try {
      let response;

      if(newPassword && code){
        response = await Auth.forgotPasswordSubmit(username, code, newPassword)
      } else {
        response = await Auth.forgotPassword(username);
      }

      resolve(response)

    } catch (error) {
      reject(error)
    }
    
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(async function (resolve, reject) {
    try {

      const response = await Auth.changePassword(currentUser, oldPassword, newPassword);
      resolve(response);

    } catch(error){
      reject(error)
    }
  })
}
