
import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Col, Row, Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  productMediaDeleteIcon,
  productMediaPlayIcon,
  productMediaPauseIcon, 
  productMediaStopIcon,
  productMediaFullScreenIcon, 
  dropdownAddIcon,
} from "./../../assets/images";

import { 
  HighlightedProductImage,
  HighlightedProfileImage
} from "./../";

import { 
  helpers, 
  api, 
  // authContextHelper 
} from "./../../utilities";


const HighlightedResourceAddButton: React.FunctionComponent<any> = (props:any) => {
  const { header, description, image, clickHandler } = props;


  return (
    <>
      {/* <Card border="light" className="shadow" style={{ height: "50px"}}>
      
        <div style={{width: "3%", backgroundColor: colour, height: "inherit",}}>
        </div>

        <Container style={{ width: "97%", position: "absolute"}}>
          <Row>
            <Col md={3}>
              <img src={image} style={{ margin: "15%"}} alt={`highlighted-${header}-img`}/>
            </Col>

            <Col md={9}>
              <div style={{marginTop: "5%", }}>
              <p style={{ color: "grey", marginBottom: "2%", fontWeight: "bold", fontSize: "0.8rem", lineHeight: "0.5rem"}}>
                {header}
              </p>
              <small style={{ color: "grey", fontSize: "0.5rem"}}>
                {description}
              </small>
              </div>
            </Col>
          </Row>
        </Container>
      </Card> */}

      <button onClick={clickHandler} type="button" className="card mb-3 shadow me-3 ps-0 pe-0 pt-0 button-card " style={{minWidth: "160px", maxWidth: "160px", maxHeight:"35px"}}>
        {/* <Link to="" onClick={onClickHandler}> */}
          <Row className="g-0 width-100-PC">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="col" style={{paddingLeft: "0px"}} >
                <Row className="g-0">
                  <Col className="col-12 ps-2 mt-0 nowrap" style={{textAlign: "start"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-15 mb-0">{header}</h5>
                      <h4 className="card-subheader-12 mb-0">{description}</h4>
                    {/* </a> */}
                  </Col>
                </Row>            
            </Col>
          </Row>
        {/* </Link> */}
      </button>
    </>
  );
};


const HighlightedProfileAddButton: React.FunctionComponent<any> = (props:any) => {
  const { header, description, clickHandler } = props;

  return (
    <>
      <button onClick={clickHandler} type="button" className="card mb-3 new-project-card shadow me-3 ps-0 pe-0 pt-0" style={{minWidth: "160px", maxWidth: "160px", maxHeight:"35px"}}>
        {/* <Link to="" onClick={onClickHandler}> */}
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-profile" style={{maxWidth: "10px"}}></Col>
            <Col className="col" style={{paddingLeft: "0px"}} >
                <Row className="g-0">
                  <Col className="col-12 ps-2 mt-0 nowrap" style={{textAlign: "start"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-15 mb-0">{header}</h5>
                      <h4 className="card-subheader-12 mb-0">{description}</h4>
                    {/* </a> */}
                  </Col>
                </Row>            
            </Col>
          </Row>
        {/* </Link> */}
      </button>
    </>
  );
};


const DropdownAddButton: React.FunctionComponent<any> = (props:any) => {
  const { btnClass, clickHandler } = props;

  const buttonClass =`card shadow dropdown-button-card ${btnClass}`;

  return (
    <>
      <button onClick={clickHandler} type="button" className={buttonClass}>
        {/* <Link to="" onClick={onClickHandler}> */}
          <Row className="g-0">
            <Col className="col" style={{paddingLeft: "0px"}} >
                <Row className="g-0">
                  <Col className="col-12 ps-2 pe-2 mt-0 nowrap" style={{textAlign: "start"}}>
                    <img className="k-mmh-10" src={dropdownAddIcon} alt={"Add a new item"}/>
                  </Col>
                </Row>            
            </Col>
          </Row>
        {/* </Link> */}
      </button>
    </>
  );
};


const DropdownAddNoteButton: React.FunctionComponent<any> = (props:any) => {
  const { btnClass, clickHandler } = props;

  const buttonClass =`card shadow dropdown-button-card ${btnClass}`;

  return (
    <>
      <button onClick={clickHandler} type="button" className={buttonClass}>
        {/* <Link to="" onClick={onClickHandler}> */}
          <Row className="g-0">
            <Col className="col" style={{paddingLeft: "0px"}} >
                <Row className="g-0">
                  <Col className="col-12 ps-2 pe-2 mt-0 nowrap" style={{textAlign: "start"}}>
                    <img className="k-mmh-10" src={dropdownAddIcon} alt={"Add a new item"}/>
                  </Col>
                </Row>            
            </Col>
          </Row>
        {/* </Link> */}
      </button>
    </>
  );
};


const HighlightedProjectAddButton: React.FunctionComponent<any> = (props:any) => {
  const { header, description, clickHandler } = props;


  return (
    <>
      <button onClick={clickHandler} type="button" className="card mb-3 new-project-card shadow me-3 ps-0 pe-0 pt-0" style={{minWidth: "160px", maxWidth: "160px", maxHeight:"35px"}}>
        {/* <Link to="" onClick={onClickHandler}> */}
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-project" style={{maxWidth: "10px"}}></Col>
            <Col className="col" style={{paddingLeft: "0px"}} >
                <Row className="g-0">
                  <Col className="col-12 ps-2 mt-0 nowrap" style={{textAlign: "start"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-15 mb-0">{header}</h5>
                      <h4 className="card-subheader-12 mb-0">{description}</h4>
                    {/* </a> */}
                  </Col>
                </Row>            
            </Col>
          </Row>
        {/* </Link> */}
      </button>
    </>
  );
};


const ProductControlPanel: React.FunctionComponent<any> = (props:any) => {
  const {
    medialControlHandlers,
    desktopStatus,
    iFrameID,
  } = props; 


  const toggleIframeFullScreen = () => {
    
    var iFrame = document.getElementById(iFrameID);
    if (iFrame != null) {
    //("Found Element " +  iFrameID);
      if(iFrame.requestFullscreen){
        iFrame.requestFullscreen();
      }
    } else {
      //console.log("Cannot find element" +  iFrameID);
    }
  }

  return (
    <>
      <Row>
        <Col className="p-0">
          {(desktopStatus === "STOPPED") || (desktopStatus === "PAUSED") || (desktopStatus === "HIBERNATION")? 
            (<Link to="" onClick={medialControlHandlers.handleProductStart}>
              <span>
                <img className="k-ctrlImg" src={productMediaPlayIcon} alt={"Icon"} />
              </span>
            </Link>)
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaPlayIcon} alt={"Icon"} />
            </span>}
        </Col>
        <Col className="p-0">
          {(desktopStatus === "RUNNING") ?
            <Link to="" onClick={medialControlHandlers.handleProductHibernate}>
              <span>
                <img className="k-ctrlImg" src={productMediaPauseIcon} alt={"Icon"} />
              </span>
            </Link>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaPauseIcon} alt={"Icon"} />
            </span>
          }
        </Col>
        <Col className="p-0">
          {(desktopStatus === "RUNNING") ?
            <Link to="" onClick={medialControlHandlers.handleProductStop}>
              <span>
                <img className="k-ctrlImg" src={productMediaStopIcon} alt={"Icon"} />
              </span>
            </Link>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaStopIcon} alt={"Icon"}/>
            </span>
          }
        </Col>
        <Col className="p-0">
          {(desktopStatus === "RUNNING") ?
            <Link to="" onClick={medialControlHandlers.handleProductFullScreen}>
              <span>
                <img onClick={toggleIframeFullScreen} className="k-ctrlImg" src={productMediaFullScreenIcon} alt={"Icon"} />
              </span>
            </Link>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaFullScreenIcon} alt={"Icon"} />
            </span>
          }
        </Col>
        <Col className="p-0">
          {(desktopStatus === "STOPPED") || (desktopStatus === "RUNNING") || (desktopStatus === "HIBERNATION") ?
            <Link to="" onClick={medialControlHandlers.handleProductDelete}>
              <span>
                <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Icon"}/>
              </span>
            </Link>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"} />
            </span>
          }
        </Col>

      </Row>
    </>
  )
}


const ProductControlPanelMinimal: React.FunctionComponent<any> = (props:any) => {
  const {
    mediaControlHandlers,
    status
  } = props; 

  //console.log("desktop state in product control minimal panel: ", status);

  return (
    <>
      <Row className="m-0">
        <Col className="p-0 col-4">
          {(status === "STOPPED") || (status === "PAUSED") || (status === "HIBERNATION")? 
            (<div onClick={mediaControlHandlers && mediaControlHandlers.handleProductStart} style={{cursor: "pointer"}}>
              <span>
                <img className="k-ctrlImg" src={productMediaPlayIcon} alt={"Icon"} />
              </span>
            </div>)
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaPlayIcon} alt={"Icon"} />
            </span>}
        </Col>
        <Col className="p-0 col-4">
          {(status === "RUNNING") ?
            <div onClick={mediaControlHandlers && mediaControlHandlers.handleProductHibernate} style={{cursor: "pointer"}}>
              <span>
                <img className="k-ctrlImg" src={productMediaPauseIcon} alt={"Icon"} />
              </span>
            </div>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaPauseIcon} alt={"Icon"} />
            </span>
          }
        </Col>
        <Col className="p-0 col-4">
          {(status === "RUNNING") ?
            <div onClick={mediaControlHandlers && mediaControlHandlers.handleProductStop} style={{cursor: "pointer"}}>
              <span>
                <img className="k-ctrlImg" src={productMediaStopIcon} alt={"Icon"} />
              </span>
            </div>
            : 
            <span>
              <img className="k-ctrlImg k-fade" src={productMediaStopIcon} alt={"Icon"}/>
            </span>
          }
        </Col>
      </Row>
    </>
  )
}


const ProfileLinkedProductButton: React.FunctionComponent<any> = (props:any) => {
  let { product, handleProfileLinkedProductSelected } = props;

  const [productStatus, setProductStatus] = useState<string>(product && product.state && product.state.toUpperCase());

  const getProfileProductStatus = async () => {
    const desktopData = await api.getProduct({
      body: {
        id: product && product.id
      }
    });

    setProductStatus(desktopData && desktopData.state && desktopData.state.toUpperCase());
    
    product.state = desktopData && desktopData.state && desktopData.state.toUpperCase();
  }

  const handleDesktopStart = async(event: any) => {
    event.preventDefault();
    const res  = await api.startProduct({
      body: {
        id: product && product.id
      }
    });
    
    //console.log("LOG : API RESPONSE",res)
    if (res.data.result === 'successful') {
      toast.success('Successfully started product', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    } else {
      toast.error('Product failed to start', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    }
    
  }

  const  handleDesktopStop = async (event: any) => {
    event.preventDefault();

    const res  = await api.stopProduct({
      body: {
        id: product && product.id
      }
    });
    //console.log("LOG : API RESPONSE",res)
    if (res.data.result === 'successful') {
      toast.success('Successfully stopped product', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    } else {
      toast.error('Product failed to stop', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    }

  }

  const  handleDesktopFullScreen = (event: any) => {
    event.preventDefault();
  }

  const  handleDesktopHibernate = async(event: any) => {
    event.preventDefault();

    const res = await api.hibernateProduct({
      body: {
        id: product && product.id
      }
    });

    //console.log("LOG : API RESPONSE",res)
    
    if (res.data.result === 'successful') {
      toast.success('Successfully hibernated product', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    } else {
      toast.error('Product failed to hibernate', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });
    }

  }

  const  handleDesktopDelete = (event: any) => {
    event.preventDefault();

    // setDeleteDesktopModalShow(true)
    //console.log("TBD: Delete not implemented => go to product's page")
    return
  }

  helpers.useInterval(() => {
    getProfileProductStatus();

  }, 1000 * 10);
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>

        <Row className="m-0" >
          <OverlayTrigger delay={{ show: 250, hide: 1500 }} placement="bottom" overlay={
            <Popover>
              <Popover.Body className="ps-1 pe-1 pt-0 pb-1 k-mmw-90">
                <span className="card-subheader-12">Product Control</span>
                <ProductControlPanelMinimal
                  status={productStatus}
                  mediaControlHandlers={{
                    handleProductHibernate: handleDesktopHibernate,
                    handleProductFullScreen: handleDesktopFullScreen,
                    handleProductStop: handleDesktopStop,
                    handleProductStart: handleDesktopStart
                  }}
                />
              </Popover.Body>
           </Popover>
          }>
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
          </OverlayTrigger>
          <Col className="ps-0 ms-0">
            <Row
              style={{cursor: "pointer"}}
              onClick={(event) => handleProfileLinkedProductSelected(event, product.id)}
              className="m-0"
            >
              <div className="row g-0 p-1">
                <div className="col-2-5 ps-1 pt-1 mb-0" >
                  <HighlightedProductImage
                    product={product.product}
                    status={productStatus}
                    system={product.system}
                  />
                </div>
                <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                  {/* <a className="nodecor"> */}
                    <h5 className="card-header-0 mb-0">{product.name}</h5>
                    <h4 className="card-subheader-0 mb-0">{product.description}&nbsp;</h4>
                  {/* </a> */}
                </div>
              </div>   
            </Row>   
          </Col>
        </Row>
      </Card>
    </>
  );
};


const ProjectLinkedProductButton: React.FunctionComponent<any> = (props:any) => {
  const { header, description, status, product, system } = props;
  //console.log("Highlighted Product: logging props: ", props)
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>

        <Row className="g-0">
          <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
          <Col className="ps-0">
              <div className="row g-0 p-1">
                <div className="col-2-5 ps-1 pt-1 mb-0" >
                  <HighlightedProductImage
                    product={product}
                    status={status}
                    system={system}
                  />
                </div>
                <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                  {/* <a className="nodecor"> */}
                    <h5 className="card-header-0 mb-0">{header}</h5>
                    <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                  {/* </a> */}
                </div>
              </div>            
          </Col>
        </Row>

      </Card>
    </>
  );
};


const ProjectProfileButton: React.FunctionComponent<any> = (props:any) => {
  const { header, description } = props;
  //console.log("Highlighted Product: logging props: ", props)
 
  return (
    <>
      <Card className="mb-3 new-profile-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>

        <Row className="g-0">
          <Col className="col-md-1 new-profile-card-sidebar npc-sidebar-profile" style={{maxWidth: "10px"}}></Col>
          <Col className="ps-0">
              <div className="row g-0 p-1">
                <div className="col-2-5 ps-1 pt-1 mb-0" >
                  <HighlightedProfileImage/>
                </div>
                <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                  {/* <a className="nodecor"> */}
                    <h5 className="card-header-0 mb-0">{header}</h5>
                    <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                  {/* </a> */}
                </div>
              </div>            
          </Col>
        </Row>

      </Card>
    </>
  );
};


export {
  HighlightedResourceAddButton,
  HighlightedProfileAddButton,
  HighlightedProjectAddButton,
  ProductControlPanel,
  ProductControlPanelMinimal,
  DropdownAddButton,
  DropdownAddNoteButton,
  ProfileLinkedProductButton,
  ProjectLinkedProductButton,
  ProjectProfileButton,
};

