const { 
  subtle
} = globalThis.crypto;

const SALT_LENGTH = 32;

// Utility function to concatenate two Uint8Array arrays
function concatenate(a: Uint8Array, b: Uint8Array): Uint8Array {
  const result = new Uint8Array(a.length + b.length);
  result.set(a, 0);
  result.set(b, a.length);
  return result;
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  var binary = "";
  const bytes = new Uint8Array(buffer);
  for (var i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export type UsernamePublicKey = {
  username: string,
  publicKey: CryptoKey
}

/**
 * Represents the VaultKey, in an encrypted format
 */
export class VaultKey {
  constructor(private vaultKey: ArrayBuffer) {}

  static fromBase64(base64: string): VaultKey {
    return new VaultKey(base64ToArrayBuffer(base64));
  }

  getAsBase64(): string {
    return arrayBufferToBase64(this.vaultKey);
  }

  getAsArrayBuffer(): ArrayBuffer {
    return this.vaultKey;
  }

  /**
   * Creates an AES256-GCM key to encrypt a vault, encrypts vault key with the
   * users public key, and returns the encrypted vault key
   * @param publicKey the users public key, to encrypt the vault key
   * @returns the vault key, encrypted with the user's public key
   */
  static async createVaultKey(usernameToPublicKey: Array<UsernamePublicKey>): Promise<Record<string, string>> {    
    const vaultKey = await subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true, //extractable
      ["encrypt", "decrypt"]
    )

    let wrappedVaultKeys: Record<string, string> = {};

    for(const entry of usernameToPublicKey) {
      const base64 = await subtle.wrapKey("raw", vaultKey, entry.publicKey, {
        name: "RSA-OAEP",
      }).then((exportedKey) => new VaultKey(exportedKey).getAsBase64());

      wrappedVaultKeys[entry.username] = base64;
    }

    return wrappedVaultKeys;
  }
}

/**
 * Represents a decrpyed/unwrapped vault key
 * if the users password is wrong returns null
 */
export class UnwrappedVaultKey {
  private constructor(public vaultKey: CryptoKey) {}

  static async fromWrappedVaultKey(
    wrappedVaultKey: VaultKey,
    password: string,
    userWrappedPrivateKey: WrappedCryptoKey
  ): Promise<UnwrappedVaultKey | null> {
    try {
      const unwrappedPrivateKey = await unwrapCryptoKey(
        userWrappedPrivateKey,
        password
      );
      const unwrappedVaultKey = await subtle.unwrapKey(
        "raw",
        wrappedVaultKey.getAsArrayBuffer(),
        unwrappedPrivateKey,
        {
          name: "RSA-OAEP",
        },
        "AES-GCM",
        true,
        ["encrypt", "decrypt"]
      );
      return new UnwrappedVaultKey(unwrappedVaultKey);
    } catch {
      return null;
    }
  }
}

type SerializedSecret = {
  id: string
  modified: number;
  data_key_hash: string;
  data: string;
  created: number;
};

/**
 * The format that GET Vault sends to you
 */
export type SerializedVault = {
  header: string;
  key: Record<string, string>;
  secrets: Array<SerializedSecret>;
};

type PasswordChangeset = {
  old_secret: string;
  new_secret: string;
};

type HeaderChangeset = {
  old_header: string;
  new_header: string;
};

/**
 * The format to send to the UPDATE vault method
 */
class VaultChangeset {
  constructor(
    public header: HeaderChangeset,
    public secrets: Record<string, PasswordChangeset>
  ) {}
}

export class Vault {
  constructor(
    private vaultData: SerializedVault,
    private unwrappedVaultKey: UnwrappedVaultKey
  ) {}

  /**
   * Retrieves an item from the Vault, if it doesn't exist yet you get the empty string
   * @param uuid the secret to retrieve
   * @param usersPassword the current users password, to decrypt the vault
   * @returns the decrypted password value if present, or the emptry string if not
   */
  getAndDecryptItemFromVault(uuid: string): Promise<string> {
    const secret = this.vaultData.secrets.find(secret => secret.id === uuid);
    if (
      secret &&
      secret.data.length > 0
    ) {
      const base64EncodedData = secret.data;
      const encryptedItem = base64ToArrayBuffer(base64EncodedData);
      const decodedPassword = decryptItem(
        this.unwrappedVaultKey,
        encryptedItem
      );
      return decodedPassword;
    } else {
      return Promise.resolve("");
    }
  }

  /**
   * Adds a password to the vault
   * invalidates your local vault state so you need to call GET VAult again and recreate the Vault object
   * @param passwordToAdd the password to encrypt and add to the vault
   * @param secretMetadata the information about the secret, to add to the header
   * @param usersPassword the current users password, to decrypt the vault
   * @returns the changeset that needs to be sent to UPDATE Vault
   */
  async addOrUpdateItemInVault(
    passwordToAdd: string,
    secretMetadata: SecretMetadata
  ): Promise<VaultChangeset> {
    // This header modification bit is awful and needs refactoring
    let previousEncryptedPassword = this.vaultData.secrets.find(secret => secret.id === secretMetadata.secretId);
    let previousEncryptedPasswordValue = "";
    let newHeader;
    //console.log("Adding a password: ", passwordToAdd);
    //console.log("Secret metadata: ", secretMetadata);
    //console.log("This vault: ", this.vaultData);
    if (previousEncryptedPassword) {
      //console.log("The secret already exists");
      if (previousEncryptedPassword.data.length > 0) {
        //console.log("The secret has a length");
        previousEncryptedPasswordValue =
          previousEncryptedPassword.data;
      }
      //console.log("Creating a temp header")
      let tempHeader: ArrayBuffer;
      if (this.vaultData.header.length > 0) {
        //console.log("The header has length")

        tempHeader = await this.removeSecretFromVaultMetadata(
          this.unwrappedVaultKey,
          base64ToArrayBuffer(this.vaultData.header),
          secretMetadata.secretId
        );
        //console.log("removed the secret from the header")

      } else {
        //console.log("creating a blank header")

        tempHeader = new ArrayBuffer(0);
      }

      //console.log("Adding secret to header")

      newHeader = await this.addSecretToVaultMetadata(
        this.unwrappedVaultKey,
        tempHeader,
        secretMetadata
      );
      //console.log("added secret to header")

    } else {
      //console.log("ADding a secret to header that didn't exist")

      newHeader = await this.addSecretToVaultMetadata(
        this.unwrappedVaultKey,
        base64ToArrayBuffer(this.vaultData.header),
        secretMetadata
      );

      //console.log("ADded a secret to header that didn't exist")

    }

    const encryptedItem = await encryptItem(
      this.unwrappedVaultKey,
      passwordToAdd
    );

    //console.log("encrypted the secret")

    const base64EncodedItem = arrayBufferToBase64(encryptedItem);

    const previousHeader = this.vaultData.header;

    //console.log("Creating password changeset")


    let passwordChanges: Record<string, PasswordChangeset> = {};
    passwordChanges[secretMetadata.secretId] = {
      old_secret: previousEncryptedPasswordValue,
      new_secret: base64EncodedItem,
    };

    //console.log("changeset created")


    return new VaultChangeset(
      {
        old_header: previousHeader,
        new_header: arrayBufferToBase64(newHeader),
      },
      passwordChanges
    );
  }

  /**
   * Removes a password from the vault,
   * invalidatess your local vault state so you need to call GET VAult again and recreate the Vault object
   * In the case the secret is not in the vault you will get an empty changeset back
   * @param secretUUIDtoRemove the uuid of the secret to remove
   * @returns the changeset that needs to be sent to UPDATE Vault
   */
  async removeItemFromVault(
    secretUUIDtoRemove: string
  ): Promise<VaultChangeset> {
    const secret = this.vaultData.secrets.find(secret => secret.id === secretUUIDtoRemove);

    if (secret) {
      const previousHeader = this.vaultData.header;

      const newHeader = await this.removeSecretFromVaultMetadata(
        this.unwrappedVaultKey,
        base64ToArrayBuffer(previousHeader),
        secretUUIDtoRemove
      );

      let oldPassword = secret.data;

      let passwordChanges: Record<string, PasswordChangeset> = {};
      passwordChanges[secretUUIDtoRemove] = {
        old_secret: oldPassword,
        new_secret: "",
      };

      return new VaultChangeset(
        {
          old_header: previousHeader,
          new_header: arrayBufferToBase64(newHeader),
        },
        passwordChanges
      );
    } else {
      return new VaultChangeset(
        {
          old_header: this.vaultData.header,
          new_header: this.vaultData.header,
        },
        {}
      );
    }
  }

  /**
   * Gets the vault header which contains data about each of the passwords
   * @param thisUsersPassword the current users password, to decrypt the vault
   * @returns An array containing data about each of the passwords
   */
  async getDecryptedVaultHeader(): Promise<VaultHeader> {
    if (!this.vaultData.header) {
      return new VaultHeader([]);
    }
    let decryptedHeader = await decryptItem(
      this.unwrappedVaultKey,
      base64ToArrayBuffer(this.vaultData.header)
    );

    const deserialised: Array<SecretMetadata> = JSON.parse(decryptedHeader);

    deserialised.forEach(
      (secretMetadata: SecretMetadata, index, originalArray) => {
        const serialisedSecret = this.vaultData.secrets.find(secret => secret.id === secretMetadata.secretId);
        secretMetadata.created = serialisedSecret?.created;
        secretMetadata.modified = serialisedSecret?.modified;
        originalArray[index] = secretMetadata;
      }
    );

    return new VaultHeader(deserialised);
  }

  private async addSecretToVaultMetadata(
    unwrappedVaultKey: UnwrappedVaultKey,
    encryptedVaultHeader: ArrayBuffer,
    secretMetadata: SecretMetadata
  ): Promise<ArrayBuffer> {
    let vaultHeader: VaultHeader;
    if (encryptedVaultHeader.byteLength === 0) {
      vaultHeader = new VaultHeader(new Array<SecretMetadata>());
    } else {
      const deserialisedVaultHeader: Array<SecretMetadata> = JSON.parse(
        await decryptItem(unwrappedVaultKey, encryptedVaultHeader)
      );

      vaultHeader = new VaultHeader(deserialisedVaultHeader);
    }

    vaultHeader.addSecret(secretMetadata);

    return encryptItem(
      unwrappedVaultKey,
      JSON.stringify(vaultHeader.listSecrets())
    );
  }

  private async removeSecretFromVaultMetadata(
    unwrappedVaultKey: UnwrappedVaultKey,
    encryptedVaultHeader: ArrayBuffer,
    uuid: string
  ): Promise<ArrayBuffer> {
    let decryptedVaultHeader = await decryptItem(
      unwrappedVaultKey,
      encryptedVaultHeader
    );

    const deserialisedVaultHeader: Array<SecretMetadata> =
      JSON.parse(decryptedVaultHeader);

    let vaultHeader: VaultHeader = new VaultHeader(deserialisedVaultHeader);

    vaultHeader.removeSecret(uuid);

    return encryptItem(
      unwrappedVaultKey,
      JSON.stringify(vaultHeader.listSecrets())
    );
  }

  public async wrapVaultKeyForUser(serialisedPublicKey: string): Promise<string> {
    const deserialisedPublicKey =  await subtle.importKey(
      "spki",
      base64ToArrayBuffer(serialisedPublicKey),
      { name: "RSA-OAEP", hash: "SHA-256" },
      true,
      ["wrapKey", "encrypt"]
    );

    const base64 = await subtle.wrapKey("raw", this.unwrappedVaultKey.vaultKey, deserialisedPublicKey, {
      name: "RSA-OAEP",
    }).then((exportedKey) => new VaultKey(exportedKey).getAsBase64());
    

    return base64;
  }
}

// START Things to generate and wrap user keys
export class CryptoData {
  constructor(
    public publicKey: CryptoKey,
    public wrappedPrivateKey: WrappedCryptoKey
  ) {}

  async serialize(): Promise<SerializedCryptoData> {
    return {
      public_key: arrayBufferToBase64(
        await subtle.exportKey("spki", this.publicKey)
      ),
      wrapped_private_key: this.wrappedPrivateKey.serialize(),
    };
  }

  static async deserialize(
    serialized: SerializedCryptoData
  ): Promise<CryptoData> {
    return new CryptoData(
      await subtle.importKey(
        "spki",
        base64ToArrayBuffer(serialized.public_key),
        { name: "RSA-OAEP", hash: "SHA-256" },
        true,
        ["wrapKey", "encrypt"]
      ),
      WrappedCryptoKey.deserialize(serialized.wrapped_private_key)
    );
  }
}

interface SerializedWrappedCryptoKey {
  private_key: string;
  salt: string;
  iv: string;
}

export interface SerializedCryptoData {
  public_key: string;
  wrapped_private_key: SerializedWrappedCryptoKey;
}

export class SecretMetadata {
  constructor(
    public secretId: string,
    public title: string,
    public username: string,
    public created?: number,
    public modified?: number
  ) {}
}

export class VaultHeader {
  constructor(private metadata: Array<SecretMetadata>) {}

  listSecrets() {
    return this.metadata.sort((a, b) => {
      if (a.modified && b.modified) {
        if (a.modified < b.modified) {
          return 1;
        } else if (a.modified > b.modified) {
          return -1;
        }
      }
      if (a.created && b.created) {
        if (a.created < b.created) {
          return 1;
        } else if (a.created > b.created) {
          return -1;
        }
      }
      if (a.title < b.title) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  addSecret(secret: SecretMetadata): void {
    this.metadata.push(secret);
  }

  removeSecret(uuid: string): void {
    const indexToRemove = this.metadata.findIndex(
      (element) => element.secretId === uuid
    );
    if (indexToRemove > -1) {
      this.metadata.splice(indexToRemove, 1);
    }
  }
}

/**
 * Contains the users private key and associated data, in an encrypted form
 */
class WrappedCryptoKey {
  constructor(
    public wrappedPrivateKey: ArrayBuffer,
    public salt: Uint8Array,
    public iv: Uint8Array
  ) {}

  /**
   *
   * @returns Exports the private key and associated data in a format suitable for sending across the network
   */
  serialize(): SerializedWrappedCryptoKey {
    return {
      private_key: arrayBufferToBase64(this.wrappedPrivateKey),
      salt: arrayBufferToBase64(this.salt),
      iv: arrayBufferToBase64(this.iv),
    };
  }

  /**
   * Turns the network serialized format back into the useable crypto key object
   * @param serialized the text/serialized form of the keys
   * @returns a useable WrappedCrpytoKey object
   */
  static deserialize(serialized: SerializedWrappedCryptoKey): WrappedCryptoKey {
    return new WrappedCryptoKey(
      base64ToArrayBuffer(serialized.private_key),
      new Uint8Array(base64ToArrayBuffer(serialized.salt)),
      new Uint8Array(base64ToArrayBuffer(serialized.iv))
    );
  }
}

/**
 * Generate a new RSA private-public keypair and wrap the private key with a password
 * @param username - used to generate a salt
 * @param password - used to derive a wrapping key
 * @returns the public key and wrapped/encrypted private key
 */
export async function generateUsersKeyPair(
  username: string,
  password: string
): Promise<CryptoData> {
  // Generate a new RSA key pair with a modulus size of 2048 bits
  const keyPair = await subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: "SHA-256",
    },
    true, // Extractable: allows the key to be exported as a CryptoKey object
    ["wrapKey", "unwrapKey"] // Key usages: public key can be userd to wrap, private key to unwrap
  );

  const wrappedPrivateKey = await wrapCryptoKey(
    keyPair.privateKey,
    username,
    password
  );

  const cryptoData = new CryptoData(keyPair.publicKey, wrappedPrivateKey);

  return cryptoData;
}

/**
 * Generates a new SALT_LENGTH salt, based around the username, and uses HMAC to stretch it
 * @param username
 * @returns the generated salt
 */
async function generateSaltAndStretch(username: string): Promise<Uint8Array> {
  const salt = new Uint8Array(SALT_LENGTH);
  await window.crypto.getRandomValues(salt);

  // Concatenate the salt and username
  const data = concatenate(salt, new TextEncoder().encode(username));

  // Use HMAC-SHA256 to derive a new salt
  const key = await subtle.importKey(
    "raw",
    new Uint8Array(SALT_LENGTH), // Use a 32-byte key for HMAC-SHA256
    { name: "HMAC", hash: "SHA-256" },
    false, // The key is not extractable
    ["sign"]
  );

  const derivedSalt = new Uint8Array(await subtle.sign("HMAC", key, data));

  return derivedSalt;
}

/**
 * Creates a key from a password that can be used to generate a wrapping key
 * @param password to use to generate the key material
 * @returns a key material to base the wrapping key on
 */
function getWrappingKeyMaterial(password: string): Promise<CryptoKey> {
  const enc = new TextEncoder();
  return subtle.importKey(
    "raw",
    enc.encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveBits", "deriveKey"]
  );
}

/**
 * Creates a wrapping key from keyMaterial and salt
 * AES-KW is used because we don't have to use an IV with this algorithm, it is designed for this purpose.
 * @param keyMaterial used to generate the wrapping key
 * @param salt
 * @returns the wrapping key
 */
function getWrappingKey(
  keyMaterial: CryptoKey,
  salt: Uint8Array
): Promise<CryptoKey> {
  return subtle.deriveKey(
    {
      name: "PBKDF2",
      salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    ["wrapKey", "unwrapKey"]
  );
}

/**
 * Wrap (encrypts) a secret key with another key, so that the original key may be stored
 * @param keyToWrap the secret key to protect
 * @param username user the key is for, used to generate the salt
 * @param password password for this user, used to create wrapping key
 * @returns the salt and wrapped key, concatenated together
 */
async function wrapCryptoKey(
  keyToWrap: CryptoKey,
  username: string,
  password: string
): Promise<WrappedCryptoKey> {
  // get the key encryption key
  const keyMaterial = await getWrappingKeyMaterial(password);
  const salt = await generateSaltAndStretch(username);
  const wrappingKey = await getWrappingKey(keyMaterial, salt);
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  const wrappedKey = await subtle.wrapKey("pkcs8", keyToWrap, wrappingKey, {
    name: "AES-GCM",
    iv,
  });

  return new WrappedCryptoKey(wrappedKey, salt, iv);
}

/**
 * Unwraps (unencrypts) the given key. The salt is stored on the front of the key
 * @param keyToUnwrap the salt and wrapped key, concatenated together
 * @param password the password to create the unwrapping key
 * @returns the unwrapped key
 */
async function unwrapCryptoKey(
  keyToUnwrap: WrappedCryptoKey,
  password: string
): Promise<CryptoKey> {
  const keyMaterial = await getWrappingKeyMaterial(password);
  const wrappingKey = await getWrappingKey(keyMaterial, keyToUnwrap.salt);

  return subtle.unwrapKey(
    "pkcs8", // import format
    keyToUnwrap.wrappedPrivateKey, // ArrayBuffer representing key to unwrap
    wrappingKey, // CryptoKey representing key encryption key
    {
      // algorithm params for key encryption key
      name: "AES-GCM",
      iv: keyToUnwrap.iv,
    }, // algorithm identifier for key encryption key
    { name: "RSA-OAEP", hash: "SHA-256" }, // algorithm identifier for key to unwrap
    true, // extractability of key to unwrap
    ["decrypt", "unwrapKey"] // key usages for key to unwrap
  );
}

// END Things to generate and wrap user keys

// START things to create and add things to a vault

/**
 * Decrypts the vault key with an existing user's private key, and re-encrypts it with the new user's public key,
 * so that the new user may have access to it
 * @param vaultKey The Vault Key to share
 * @param password the existing users password, to unlock the private key
 * @param existingUserWrappedPrivateKey to unlock the vault key for re-encryption
 * @param newUserPublicKey The public key for the user it is being shared to
 * @returns The vault key encrypted for the new user, so that they may have access to the vault
 */
export async function shareVaultKey(
  vaultKey: ArrayBuffer,
  password: string,
  existingUserWrappedPrivateKey: WrappedCryptoKey,
  newUserPublicKey: CryptoKey
): Promise<ArrayBuffer> {
  const unwrappedPrivateKey = await unwrapCryptoKey(
    existingUserWrappedPrivateKey,
    password
  );
  const unwrappedVaultKey = await subtle.unwrapKey(
    "raw",
    vaultKey,
    unwrappedPrivateKey,
    {
      name: "RSA-OAEP",
    },
    "AES-GCM",
    true,
    ["encrypt", "decrypt"]
  );

  return subtle.wrapKey("raw", unwrappedVaultKey, newUserPublicKey, {
    name: "RSA-OAEP",
  });
}

/**
 * Encrypts the given item an item
 * @param unwrappedVaultKey the unencrypted Vault Key
 * @param toEncrypt the value to encrypt
 * @returns the encrypted item
 */
async function encryptItem(
  unwrappedVaultKey: UnwrappedVaultKey,
  toEncrypt: string
): Promise<ArrayBuffer> {
  // The iv must never be reused with a given key.
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  let enc = new TextEncoder();

  const cipherText = await subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    unwrappedVaultKey.vaultKey,
    enc.encode(toEncrypt)
  );

  return concatenate(iv, new Uint8Array(cipherText));
}

/**
 * Decrypts the passed value
 * @param unwrappedVaultKey the unencrypted Vault Key
 * @param toDecrypt the value to decrypt
 * @returns the decrypted item
 */
async function decryptItem(
  unwrappedVaultKey: UnwrappedVaultKey,
  toDecrypt: ArrayBuffer
): Promise<string> {
  const iv = new Uint8Array(toDecrypt.slice(0, 12));
  const cipherText = toDecrypt.slice(12);

  const decrypted = await subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    unwrappedVaultKey.vaultKey,
    cipherText
  );

  let enc = new TextDecoder("utf-8");

  return enc.decode(decrypted);
}

// END things to create and add items to vault
