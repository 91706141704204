
export const DEFAULT_CONFIG = ( window as any)["DEFAULT_CONFIG"];

export enum DEFAULT_PRODUCT_STATES {
  RUNNING = "RUNNING",
  BUILDING = "BUILDING",
  STOPPING = "STOPPING",
  STOPPED = "STOPPED",
  STARTING = "STARTING",
  HIBERNATING = "HIBERNATING",
  HIBERNATION = "HIBERNATION",
  ERROR = "ERROR",
};



export const DEFAULT_FORM_VALIDATION = {
  PROFILE: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{5,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
  },
  PROJECT: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{5,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
  },
  USER: {
    USERNAME: new RegExp('^[a-z][a-z0-9-]{2,20}[a-z]$'),
    DISPLAY_NAME: new RegExp('^[a-zA-Z- ]{5,30}$'),
    PASSWORD: new RegExp('^(?=.*[A-Z])(?=.*[!@#.$&*])(?=.*[0-9])(?=.*[a-z]).{8,64}$'),
    BIO: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    EMAIL: new RegExp('^[a-zA-Z0-9][a-zA-Z0-9\\-_\\.\\+]+@[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]+$'),
    PHONE_NUMBER: new RegExp('^[0-9+]{8,14}$'),
  },
  GROUP: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{5,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
  },
  PRODUCT: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{5,25}$'),
    USERNAME: new RegExp('^[a-z]{4,15}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
  },
  NOTE: {

  },
  VAULT: {
    TITLE: new RegExp('(.|\\s)*\\S(.|\\s)*'),
    PASSWORD: new RegExp('(.|\\s)*\\S(.|\\s)*'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
    USERNAME: new RegExp('(.|\\s)*\\S(.|\\s)*'),
  }
};



export const DEFAULT_NATIONALITIES: string[] = [ 
  "Afghan", "Albanian", "Algerian", "American", "Andorran", "Angolan", "Antiguans", "Argentinean", 
  "Armenian", "Australian", "Austrian", "Azerbaijani", "Bahamian", "Bahraini", "Bangladeshi", 
  "Barbadian", "Barbudans", "Batswana", "Belarusian", "Belgian", "Belizean", "Beninese", 
  "Bhutanese", "Bolivian", "Bosnian", "Brazilian", "British", "Bruneian", "Bulgarian", "Burkinabe", 
  "Burmese", "Burundian", "Cambodian", "Cameroonian", "Canadian", "Cape Verdean", "Central African", 
  "Chadian", "Chilean", "Chinese", "Colombian", "Comoran", "Congolese", "Costa Rican", "Croatian", 
  "Cuban", "Cypriot", "Czech", "Danish", "Djibouti", "Dominican", "Dutch", "East Timorese", "Ecuadorean", 
  "Egyptian", "Emirian", "Equatorial Guinean", "Eritrean", "Estonian", "Ethiopian", "Fijian", "Filipino", 
  "Finnish", "French", "Gabonese", "Gambian", "Georgian", "German", "Ghanaian", "Greek", "Grenadian", 
  "Guatemalan" , "Guinea-Bissauan", "Guinean", "Guyanese", "Haitian", "Herzegovinian", "Honduran", 
  "Hungarian", "I-Kiribati", "Icelander", "Indian", "Indonesian", "Iranian", "Iraqi", "Irish", "Israeli", 
  "Italian", "Ivorian", "Jamaican", "Japanese", "Jordanian", "Kazakhstani", "Kenyan", "Kittian and Nevisian", 
  "Kuwaiti", "Kyrgyz", "Laotian", "Latvian", "Lebanese", "Liberian", "Libyan", "Liechtensteiner", "Lithuanian", 
  "Luxembourger", "Macedonian", "Malagasy", "Malawian", "Malaysian", "Maldivian", "Malian", "Maltese", 
  "Marshallese", "Mauritanian", "Mauritian", "Mexican", "Micronesian", "Moldovan", "Monacan", "Mongolian", 
  "Moroccan", "Mosotho", "Motswana", "Mozambican", "Namibian", "Nauruan", "Nepalese", "New Zealander", 
  "Ni,Vanuatu", "Nicaraguan", "Nigerian", "Nigerien", "North Korean", "Northern Irish", "Norwegian", "Omani", 
  "Pakistani", "Palauan", "Panamanian", "Papua New Guinean", "Paraguayan", "Peruvian", "Polish", "Portuguese", 
  "Qatari", "Romanian", "Russian", "Rwandan", "Saint Lucian", "Salvadoran", "Samoan", "San Marinese", 
  "Sao Tomean", "Saudi", "Scottish", "Senegalese", "Serbian", "Seychellois", "Sierra Leonean", "Singaporean", 
  "Slovakian", "Slovenian", "Solomon Islander", "Somali", "South African", "South Korean", "Spanish", 
  "Sri Lankan", "Sudanese", "Surinamer", "Swazi", "Swedish", "Swiss", "Syrian", "Taiwanese", "Tajik", "Tanzanian", 
  "Thai", "Togolese", "Tongan", "Trinidadian/Tobagonian", "Tunisian", "Turkish", "Tuvaluan", "Ugandan", 
  "Ukrainian", "Uruguayan", "Uzbekistani", "Venezuelan", "Vietnamese", "Welsh", "Yemenite", "Zambian", "Zimbabwean"
]


export const DEFAULT_READABLE_PRODUCT_TYPES: any = {
  "vm": {
    "NAME": "Desktop"
  },
  "browser": {
    "NAME": "Browser"
  },
  "chat": {
    "NAME": "Chat"
  },
  "vtc": {
    "NAME": "Video"
  }
}

export const DEFAULT_RESOURCE_TYPES = {
  PRODUCT: {
    API_KEY: "product"
  },
  PROFILE: {
    API_KEY: "profile"
  },
  PROJECT: {
    API_KEY: "project"
  }
}

export const DEFAULT_PRODUCT_TYPES = {
  BROWSER: {
    API_KEY: "browser"
  },
  COMMUNICATE: {
    API_KEY: "communicate"
  },
  DESKTOP: {
    API_KEY: "vm"
  },
  CHAT: {
    API_KEY: "chat"
  },
  VTC: {
    API_KEY: "vtc"
  },
}

export const DEFAULT_API_METHODS = {
  PUT: "PUT",
  POST: "POST",
  PATCH: "PATCH",
  GET: "GET",
  DELETE: "DELETE",
}