import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';

import { authHooks, authContextHelper } from './../../utilities'
import { Password } from '../../components/auth'

const ChangePassword: React.FunctionComponent = () => {

  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = authHooks.useValidPassword('')

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = authHooks.useValidPassword('')

  const isValid = !oldPasswordIsValid || oldPassword.length === 0 || !newPasswordIsValid || newPassword.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  const changePassword = async () => {
    try {
      await authContext.changePassword(oldPassword, newPassword)
      setReset(true)
    } catch (err: any) {
      setError(err.message)
    }
  }

  const signOut = async () => {
    try {
      await authContext.signOut()
      history.push('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <h3 className="mb-4">Reset password</h3>
        <Form>
          <Form.Group id="email" className="mb-4">
            <Form.Label>Email</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
              <Form.Control autoFocus required type="email" placeholder="example@company.com" />
            </InputGroup>
          </Form.Group>
          <Form.Group id="password" className="mb-4">
            <Form.Label>Current Password</Form.Label>
            <Password label="Your Current Password" passwordIsValid={oldPasswordIsValid} setPassword={setOldPassword} />
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>New Password</Form.Label>
            <Password label="Your New Password" passwordIsValid={newPasswordIsValid} setPassword={setNewPassword} />
          </Form.Group>
          <Form.Group id="confirmPassword" className="mb-4">
            <Form.Label>New Password (Confirm)</Form.Label>
            <Password label="Confirm New Password" passwordIsValid={newPasswordIsValid} setPassword={setNewPassword} />
          </Form.Group>
          <Button variant="primary" type="button" className="w-100" disabled={isValid} onClick={changePassword}>
            Reset password
          </Button>
        </Form>
      </div>
    </>
  )

  const passwordReset = (
    <>
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
        <h3 className="mb-4 text-center">Password Changed</h3>
        <p className="text-center">
          <Card.Link onClick={signOut} className="text-gray-700">
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Sign In
          </Card.Link>
          {}
        </p>
      </div>
      
    </>
  )

  return (
    <>
      <main>
        <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link onClick={() => history.goBack()} className="text-gray-700">
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back
                </Card.Link>
                {}
              </p>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                {!reset? updatePassword: passwordReset}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}


export default ChangePassword;