import React from 'react'
import { InputGroup, Form } from 'react-bootstrap';


export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  emailIsValid,
  setEmail,
}) => {
  return (

    <InputGroup
    className="kuro-input-group"
    hasValidation
    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    //("CHANGING EMAIL ... : ", evt.target.value)
      setEmail(evt.target.value)
    }}>
        <InputGroup.Text id="useremail" dangerouslySetInnerHTML={{__html: '&#128274'}}></InputGroup.Text>
        <Form.Control
          type="email"
          placeholder="Email"
          // aria-label="Email"
          aria-describedby="useremail"
          // isValid= {emailIsValid ? true : false}
        />
      </InputGroup>
  )
}

export const Password: React.FunctionComponent<{
  label: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ label, passwordIsValid, setPassword }) => {
  return (
  <InputGroup
  // hasValidation
      className="kuro-input-group"
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {

        setPassword(evt.target.value)
      }}>
          {/* <InputGroup.Text id="password">
            <FontAwesomeIcon icon={faUnlockAlt} />
          </InputGroup.Text> */}
          <Form.Control
            type="password"
            placeholder="password"
            required
            // aria-label="Password"
            aria-describedby="password"
            // isValid= {passwordIsValid ? true : false}
          />
          <Form.Control.Feedback>
        {passwordIsValid ? null : 'Minimum 8 characters'}
      </Form.Control.Feedback>
    </InputGroup>
  )
}

export const Username: React.FunctionComponent<{ usernameIsValid: boolean; setUsername: (_: string) => void }> = ({
  usernameIsValid,
  setUsername,
}) => {
  return (
    <InputGroup 
      className="kuro-input-group"
      // hasValidation // Add this to indicate validation marker
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {

        setUsername(evt.target.value)
      }}>
        {/* <InputGroup.Text id="username">
          <FontAwesomeIcon icon={faUser} />
        </InputGroup.Text> */}
        <Form.Control
          placeholder="username"
          // aria-label="Username"
          aria-describedby="username"
          autoFocus 
          required
          // isValid= {usernameIsValid ? true : false}
        />
    </InputGroup>


  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <InputGroup 
      hasValidation
      className="kuro-input-group"
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    >
      {/* <InputGroup.Text id="validationCode" dangerouslySetInnerHTML={{__html: '&#128274'}}></InputGroup.Text> */}
      <Form.Control
        placeholder="code"
        // aria-label="Code"
        aria-describedby="validationCode"
        // isValid= {codeIsValid ? true : false}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        autoComplete="one-time-code"
        autoFocus
        
      />
      <Form.Control.Feedback type="valid">
      {codeIsValid ? null : 'Minimum 6 characters'}
      </Form.Control.Feedback>
    </InputGroup>
  )
}



export const ProductName: React.FunctionComponent<{ productNameIsValid: boolean; setProductName: (_: string) => void }> = ({
  productNameIsValid,
  setProductName,
}) => {
  return (
    <InputGroup 
  
      
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setProductName(evt.target.value)
      }}>
        {/* <InputGroup.Text id="username">
          <FontAwesomeIcon icon={faUser} />
        </InputGroup.Text> */}
        <Form.Control
          placeholder="username"
          // aria-label="Username"
          aria-describedby="username"
          autoFocus 
          required
          isValid= {productNameIsValid ? true : false}
        />
        <Form.Control.Feedback>
        {productNameIsValid ? null : 'Invalid Characters'}
      </Form.Control.Feedback>
    </InputGroup>


  )
}



export const ProductDescription: React.FunctionComponent<{ productDescriptionIsValid: boolean; setProductDescription: (_: string) => void }> = ({
  productDescriptionIsValid,
  setProductDescription,
}) => {
  return (
    <InputGroup 
      className="kuro-input-group"
      // hasValidation // Add this to indicate validation marker
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setProductDescription(evt.target.value)
      }}>
        {/* <InputGroup.Text id="username">
          <FontAwesomeIcon icon={faUser} />
        </InputGroup.Text> */}
        <Form.Control
          placeholder="username"
          // aria-label="Username"
          aria-describedby="username"
          autoFocus 
          required
          isValid= {productDescriptionIsValid ? true : false}
        />
        <Form.Control.Feedback>
        {productDescriptionIsValid ? null : 'Invalid Characters'}
      </Form.Control.Feedback>
    </InputGroup>


  )
}


