import React, { useRef } from "react";
import { Col, Row, Card, Accordion, Form, Nav, Tab, } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Editor } from '@tinymce/tinymce-react';

import {
  productMediaDeleteIcon,
  noteIcon,
  passwordIcon,
  activityIcon,
  mobileIcon,
  emailIcon,
  resourceAddIcon,
} from "./../../assets/images";

import { 
  helpers,
} from './../../utilities';

import {
  ProfileAccountPasswordFormPane,
  ProductAccountPasswordFormPane,
  TwoDigitCountdownDropdown,
  DropdownAddButton,
  ResourceVaultPasswordCheck,
  DropdownAddNoteButton,
  ProductToolsVaultTab
} from './../'


const CustomToggle: React.FunctionComponent<any> = (props: {children: any, eventKey: string}) => {
  const decoratedOnClick = useAccordionButton(props.eventKey, () => 
    console.log('ok'),
  );

  return (
    <button
      type="button"
      className="p-0 accordion-button-notes width-100-PC"
      onClick={decoratedOnClick}
    >
      {props.children}
    </button>
  );
}

const NotePanel: React.FunctionComponent<any> = (props:any) => {
  const { header, date, headline, body, eventKeyID, productID, note, handleNoteClick, handleNoteDelete, noteID, handleNoteSave } = props;

  const editorRef = useRef<any>(null);
  const modifiedDate = helpers.getConditionalDateTime(date);
  
  return (
    <>
      <Accordion id={`note-accordion-${eventKeyID}`} defaultActiveKey="0" flush>
        <CustomToggle eventKey={eventKeyID}>
          <Card id={`notecard-${noteID}`} className="card note-card no-border ps-0" style={{minWidth: "280px", maxWidth: "280px"}} onClick={() => handleNoteClick({id: productID, noteID: noteID, editorRef: editorRef})}>
            <Row className="row">
              <Col className="col-12">
                <Card.Body className="card-body ps-0 pe-1 py-2" >
                  <Row className="">
                    <Col className="col-11 note-header-0 pe-0">{header.replace(/&nbsp;/g, '')}</Col>
                    <Col onClick={(e) => { e.stopPropagation(); handleNoteDelete(noteID); }} className="delete-note col-1 note-header-0 ps-0 pe-0 "><img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"}/></Col>
                  </Row>
                  <Row>
                    <Col className="col-12 pe-0 nowrap note-date-0">{modifiedDate}: <span className="nowrap note-body-0">{headline.replace(/&nbsp;/g, '')}</span></Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKeyID} className="accordion-collapse-notes p-0 m-0">
          <div className="mb-5">
            <hr className="mt-1 mb-1 ms-0 me-0"></hr>
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt12: any, editor: any) => editorRef.current = editor}
              //initialValue={body}
              init={{
                height: 200,
                menubar: false,
                //inline: true,
                branding: false,
                promotion: false,
                format: 'raw',
                plugins: [
                  'lists',
                  'powerpaste',
                  'autolink',
                  'autosave',
                  'save'
                ],
                toolbar: 'save undo redo | bold italic underline',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 'strong { font-weight: 900; }',
                toolbar_location: 'bottom',
                save_onsavecallback: () => {
                  handleNoteSave(editorRef);
                }
              }}
            />
          </div>
        </Accordion.Collapse>
        <hr className="mt-1 mb-1 ms-0 me-0"></hr>
      </Accordion>
    </>
  );
};

const NotePanelRow: React.FunctionComponent<any> = (props:any) => {
  const { header, date, headline, body, eventKeyID } = props;

  const editorRef = useRef<any>(null);

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  return (
    <>
      <Accordion id={`note-accordion-${eventKeyID}`} defaultActiveKey="0" flush className="p-0">
        <CustomToggle eventKey={eventKeyID}>
          <Card className="card note-card no-border ps-0">
            <Row className="ms-2 me-2">
              <Col className="col-12 p-0">
                <Card.Body className="card-body ps-0 pe-1 py-2" >
                  <Row className="ms-0 me-0">
                    <Col className="col-5 note-header-0 pe-0">{header.replace(/&nbsp;/g, '')}</Col>
                    <Col className="col-6 col-auto note-body-0 pe-0 nowrap">{date}: {headline.replace(/&nbsp;/g, '')}</Col>
                    <Col className="delete-note col note-header-0 ps-0 pe-0 k-txt-aln-e" onClick={() => helpers.removeNote(`note-accordion-${eventKeyID}`)} ><img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"}/></Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKeyID} className="accordion-collapse-notes ms-2 me-2">
          <div className="mb-2">
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt12: any, editor: any) => editorRef.current = editor}
              //initialValue={body}
              init={{
                skin: false,
                height: 200,
                menubar: true,
                //inline: true,
                toolbar: false,
                format: 'raw',
                branding: false,
                promotion: false,
                plugins: [
                  'lists',
                  'powerpaste',
                  'autolink',
                  'autosave',
                  'save'
                ],
                //toolbar: 'save undo redo | bold italic underline',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 'strong { font-weight: 900; }',
                toolbar_location: 'top',
                save_onsavecallback: () => {
                  //console.log('Saved');
                }
              }}
            />
          </div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

const NotePanelList: React.FunctionComponent<any> = (props:any) => {
  const { key, header, utsModified, headline, noteUrl, noteID, edit, handleNoteClick, handleNoteDelete } = props;

  // const editorRef = useRef<any>(null);
  const modifiedDate = helpers.getConditionalDateTime(utsModified);
  
  return (
    <>
      <Card id={`notecard-${noteID}`} className="card note-card no-border ps-0 pt-0 pb-0" onClick={() => handleNoteClick({noteID: noteID})}>
        <Row className="ms-2 me-2">
          <Col className="col-12 p-0">
            <Card.Body className="card-body ps-0 pe-1 py-2" >
              <Row className="ms-0 me-0">
                <Col className="col-11 note-header-0 pe-0 nowrap" >
                  <span id={`noteheader-${key}`}>{header.replace(/&nbsp;/g, '')}</span> 
                  <br/> 
                  <span id={`noteheadline-${key}`} className="note-body-1 nowrap"> {modifiedDate} <span className="note-body-0">{(headline) ? headline.replace(/&nbsp;/g, '') : " "}</span></span>
                </Col>

                <Col className="delete-note col-1 note-header-0 ps-0 pe-0 k-txt-aln-e">
                  <img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"} onClick={handleNoteDelete}/>
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const PasswordPanelList: React.FunctionComponent<any> = (props:any) => {
  const { key, id, title, utsModified, headline, handlePasswordSelect, handlePasswordDelete } = props;

  // const editorRef = useRef<any>(null);
  const modifiedDate = helpers.getConditionalDateTime(utsModified);
  
  return (
    <>
      <Card className="card note-card no-border ps-0 pt-0 pb-0" onClick={(event: any) => handlePasswordSelect(event, id)}>
        <Row className="ms-2 me-2">
          <Col className="col-12 p-0">
            <Card.Body className="card-body ps-0 pe-1 py-2" >
              <Row className="ms-0 me-0">
                <Col className="col-11 note-header-0 pe-0 nowrap" >
                  <span id={`passheader-${key}`}>{title} </span>
                  <br/> 
                  <span id={`passheadline-${key}`} className="note-body-1 nowrap"> {modifiedDate} <span className="note-body-0">{(headline) ? (headline) : "new password"}</span></span>
                </Col>
                <Col className="delete-note col-1 note-header-0 ps-0 pe-0 k-txt-aln-e">
                  <img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"} onClick={(event: any) => handlePasswordDelete(event, id)}/>
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const PasswordPanel: React.FunctionComponent<any> = (props:any) => {
  const { title, username, url, password, otp, eventKeyID } = props;

  // const editorRef = useRef<any>(null);


  

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  return (
    <>
      <Accordion id={`password-accordion-${eventKeyID}`} defaultActiveKey="0" flush>
        <CustomToggle eventKey={eventKeyID}>
          <Card className="card note-card no-border ps-0" style={{minWidth: "280px", maxWidth: "280px"}}>
            <Row className="row">
              <Col className="col-12">
                <Card.Body className="card-body ps-0 pe-1 py-2" >
                  <Row className="">
                    <Col className="col-11 note-header-0 pe-0">{title}</Col>
                    <Col onClick={() => helpers.removeNote(`password-accordion-${eventKeyID}`)} className="delete-note col-1 note-header-0 ps-0 pe-0"><img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"}/></Col>
                  </Row>
                  <Row>
                    <Col className="col-12 pe-0 nowrap note-date-0">{username}</Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKeyID} className="accordion-collapse-notes p-0 m-0">
          <div className="">
            <hr className="mt-1 mb-1 ms-0 me-0"></hr>
            <Form>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">Username:</Col><Col className="col-8 ps-0 nowrap password-details-1">{username}</Col></Row>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">Password:</Col><Col className="col-8 ps-0 nowrap password-details-1">{password}</Col></Row>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">URL:</Col><Col className="col-8 ps-0 nowrap password-details-1">{url}</Col></Row>
              <Row>
                <TwoDigitCountdownDropdown/>
              </Row>
            </Form>
          </div>
        </Accordion.Collapse>
        <hr className="mt-1 mb-1 ms-0 me-0"></hr>
      </Accordion>
    </>
  );
};

const ProductToolsPanel: React.FunctionComponent<any> = (props: any) => {
  const {
    productDetails, 

    notes,
    vault,
    decryptedVaultKey,
    preSelectedSecretId,

    vaultPasswordSubmitError,
    vaultSecretEditSubmitError,
    
    vaultHandlers,
    noteHandlers
  } = props;



  //console.log("Product TOOLS: Vault: ", vault );
  //console.log("Product TOOLS: Desktop Details: ", productDetails);



  return(
    <>
      <div id={`dropdown-${productDetails && productDetails.id}`}  className="overlayBase contentHeaderDropdown pt-2">                  
        <Tab.Container defaultActiveKey="selectedDesktop.Notes">
          <Row xs="auto" className="dropdown-tab">
            <Col className="col-1 p-0 m-0 hmh-100">
                <Nav className="justify-content-md-end">
                  <Nav.Item>
                    <Nav.Link className="dropdown-tab-header" eventKey="selectedDesktop.Notes"><img src={noteIcon} alt={"Icon"} className="dropdown-tab-icon"/></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="dropdown-tab-header" eventKey="selectedDesktop.Passwords"><img src={passwordIcon} alt={"Icon"} className="dropdown-tab-icon"/></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="dropdown-tab-header" eventKey="selectedDesktop.Email"><img src={emailIcon} alt={"Icon"} className="dropdown-tab-icon"/></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="dropdown-tab-header" eventKey="selectedDesktop.Mobile"><img src={mobileIcon} alt={"Icon"} className="dropdown-tab-icon"/></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="dropdown-tab-header" eventKey="selectedDesktop.Activity"><img src={activityIcon} alt={"Icon"} className="dropdown-tab-icon"/></Nav.Link>
                  </Nav.Item>
                </Nav>
            </Col>
            <Col className="col-11 p-0 hmh-100">
              <Row xs="auto" className="dropdownContentRow">
                <Col className="dropdownContent">
                  <Tab.Content className="height-100-PC">
                    <Tab.Pane className="height-100-PC" eventKey="selectedDesktop.Notes">
                      <Row className="width-100-PC m-0">
                        <Col className="ms-4 ps-0 pe-0">
                          <Row className="header-black-22">Notes</Row>
                          <Row className="subheader-black-22 mt-m10">Capture and prioritise</Row>
                        </Col>
                        <Col className="ms-0 mt-2 ms-2 p-0">
                          <DropdownAddNoteButton
                            btnClass={"ps-0 pe-0 pt-0 ml-95"}
                            clickHandler={noteHandlers.handlePostProductNote}
                            header={"Add Note"}
                            description={"Add Note"}
                            image={resourceAddIcon} 
                          />
                        </Col>
                      </Row>
                      <div className="scrolling-topcontainer">
                        <div className="scrolling-container pt-2">
                          { notes && notes.map((note: any, index: number) => {
                            return (
                              <Row className="m-0">
                                <Col className="pb-0 ps-3 pe-3">
                                  <NotePanel key={`prodNote-${index}`}
                                    header={note.title}
                                    date={note.modified}
                                    headline={note.headline}
                                    noteUrl={note.url}
                                    eventKeyID={index}
                                    productID={productDetails.id}
                                    handleNoteClick={noteHandlers.handleGetProductNote}
                                    handleNoteDelete={noteHandlers.handleDeleteProductNote}
                                    noteID={note.id}
                                    handleNoteSave={noteHandlers.handlePutProductNote}
                                  />
                                  </Col>
                              </Row>
                            )
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="height-100-PC" eventKey="selectedDesktop.Passwords">
                    <>
                      { decryptedVaultKey ? 
                        <ProductToolsVaultTab
                          productDetails={productDetails}

                          vault={vault}
                          decryptedVaultKey={decryptedVaultKey}
                          preSelectedSecretId={preSelectedSecretId}
            
                          vaultPasswordSubmitError={vaultPasswordSubmitError}
                          vaultSecretEditSubmitError={vaultSecretEditSubmitError}
            
                          vaultHandlers={vaultHandlers}
                        />
                        :
                        <>
                          <ResourceVaultPasswordCheck
                            vaultPasswordSubmitError={vaultPasswordSubmitError}
                            handleVaultPasswordSubmit={vaultHandlers.handleDycryptVaultSubmit}
                          />
                        </>
                      }
                    </>
                    </Tab.Pane>
                    <Tab.Pane className="height-100-PC" eventKey="selectedDesktop.Email">
                      <Row className="width-100-PC m-0">
                        <Col className="ms-4 ps-0 pe-0">
                          <Row className="header-black-22">Email</Row>
                          <Row className="subheader-black-22 mt-m10">Disposable eMail</Row>
                        </Col>
                        <Col className="ms-0 mt-2 ms-2 p-0">
                          <DropdownAddButton 
                            btnClass={"ps-0 pe-0 pt-0 ml-95"}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane className="height-100-PC" eventKey="selectedDesktop.Mobile">
                      <Row className="width-100-PC m-0">
                        <Col className="ms-4 ps-0 pe-0">
                          <Row className="header-black-22">Mobile</Row>
                          <Row className="subheader-black-22 mt-m10">Cloud mobile products</Row>
                        </Col>
                        <Col className="ms-0 mt-2 ms-2 p-0">
                          <DropdownAddButton
                            btnClass={"ps-0 pe-0 pt-0 ml-15"}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane className="height-100-PC" eventKey="selectedDesktop.Activity">
                    <Row className="width-100-PC m-0">
                        <Col className="ms-4 ps-0 pe-0">
                          <Row className="header-black-22">Review</Row>
                          <Row className="subheader-black-22 mt-m10">Monitor and Understand</Row>
                        </Col>
                        <Col className="ms-0 mt-2 ms-2 p-0">
                          
                        </Col>
                      </Row>
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  )
}

const ProductToolsPasswordItem: React.FunctionComponent<any> = (props:any) => {
  const { 
    secret,
    selectedSecret,
    vaultSecretEditSubmitError,

    handlePasswordSelect,
    handlePasswordDelete,
    handlePasswordEdit
  } = props;


  return (
    <>
      <Accordion id={`password-accordion-${secret.secretId}`} defaultActiveKey="0" flush  onSelect={(event: any) => handlePasswordSelect(event, secret.secretId)}>
        <CustomToggle 
          eventKey={secret.secretId} 
          >
          <Card className="card note-card no-border ps-0" style={{minWidth: "280px", maxWidth: "280px"}}>
            <Row className="row">
              <Col className="col-12">
                <Card.Body className="card-body ps-0 pe-1 py-2" >
                  <Row className="">
                    <Col className="col-11 note-header-0 pe-0">{secret.title}</Col>
                    <Col 
                      onClick={(event: any) => handlePasswordDelete(event, secret.secretId)} 
                      className="delete-note col-1 note-header-0 ps-0 pe-0">
                        <img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 pe-0 nowrap note-date-0">{secret.username}</Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </CustomToggle>
        <Accordion.Collapse eventKey={secret.secretId} className="accordion-collapse-notes p-0 m-0">
          <div className="">
            <hr className="mt-1 mb-1 ms-0 me-0"></hr>
            {
              selectedSecret && (selectedSecret.secretId === secret.secretId) ?
              <ProductAccountPasswordFormPane
                accountPassword={selectedSecret}
                editAccountPasswordSubmitError={vaultSecretEditSubmitError}
                handleAccountPasswordEditSubmit={handlePasswordEdit}
              />:
              null
            }
            {/* <Form>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">Username:</Col><Col className="col-8 ps-0 nowrap password-details-1">{secret.username}</Col></Row>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">Password:</Col><Col className="col-8 ps-0 nowrap password-details-1">{secret.password}</Col></Row>
              <Row><Col className="col-4 pe-0 nowrap password-details-0">URL:</Col><Col className="col-8 ps-0 nowrap password-details-1">{secret.url}</Col></Row>
              <Row>
                <TwoDigitCountdownDropdown/>
              </Row>
            </Form> */}
          </div>
        </Accordion.Collapse>
        <hr className="mt-1 mb-1 ms-0 me-0"></hr>
      </Accordion>
    </>
  );
};

const PasswordPanelRow: React.FunctionComponent<any> = (props:any) => {
  const { accountPassword, eventKeyID } = props;
  
  //console.log("vault eventKeyID: ", eventKeyID);
  return (
    <>
      <Accordion id={`password-accordion-${eventKeyID}`} defaultActiveKey="0" flush className="p-0">
        <CustomToggle eventKey={eventKeyID}>
          <Card className="card note-card no-border ps-0">
            <Row className="ms-2 me-2">
              <Col className="col-12 p-0">
                <Card.Body className="card-body ps-0 pe-1 py-2" >
                  <Row className="ms-0 me-0">
                    <Col className="col-5 note-header-0 pe-0">{accountPassword.title}</Col>
                    <Col className="col-6 col-auto note-subheader-0 pe-0">{accountPassword.username}</Col>
                    <Col className="delete-note col note-header-0 ps-0 pe-0 k-txt-aln-e" onClick={() => helpers.removeNote(`password-accordion-${eventKeyID}`)} ><img className="delete-note k-ctrlImg k-fade" src={productMediaDeleteIcon} alt={"Icon"}/></Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKeyID} className="accordion-collapse-notes p-0 m-0">
          <div id={`acord-${eventKeyID}`} className="ms-2 me-2">
            {/* <Row className="ms-0 me-0"><Col className="col-5 pe-0 nowrap password-details-0">URL:</Col><Col className="col-5 nowrap password-details-1">{accountPassword.url}</Col></Row>
            <Row className="ms-0 me-0"><Col className="col-5 pe-0 nowrap password-details-0">Username:</Col><Col className="col-5 nowrap password-details-1">{accountPassword.username}</Col></Row>
            <Row className="ms-0 me-0"><Col className="col-5 pe-0 nowrap password-details-0">Password:</Col><Col className="col-5 nowrap password-details-1">{accountPassword.password}</Col></Row>
            <Row className="ms-0 me-0"><Col className="col-5 pe-0 nowrap password-details-0">OTP:</Col><Col className="col-2 password-details-1">{accountPassword.otp}</Col><Col className="password-details-1 ps-0 mt-m2">
              <TwoDigitCountdown/></Col>
            </Row>
            <Row><hr className="mt-1 mb-1 ms-0 me-0"></hr></Row> */}
            <ProfileAccountPasswordFormPane
              accountPassword={accountPassword}
              eventKeyID={eventKeyID}
            />
          </div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export {
  NotePanel,
  NotePanelRow,
  NotePanelList,
  PasswordPanel,
  PasswordPanelRow,
  PasswordPanelList,
  ProductToolsPasswordItem,
  ProductToolsPanel
}